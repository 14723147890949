/* eslint-disable @typescript-eslint/no-non-null-assertion */

import * as React from "react";
import { repository } from "clientInstance";
import { ResourceCollection, RunbookResource, ProcessType } from "client/resources";
import List from "components/List";
import { ActionButtonType, NavigationButton, NavigationButtonType } from "components/Button";
import PaperLayout from "components/PaperLayout/index";
import { useRouteMatch } from "react-router";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent";
import PermissionCheck, { isAllowed, PermissionCheckProps } from "components/PermissionCheck/PermissionCheck";
import Permission from "client/resources/permission";
import { ProjectContextProps, useProjectContext } from "areas/projects/context";
import ListTitle from "primitiveComponents/dataDisplay/ListTitle";
import OpenDialogButton from "components/Dialog/OpenDialogButton";
import routeLinks from "routeLinks";
import AddRunbook from "./AddRunbook";
import RunbooksOnboarding from "./RunbooksOnboarding";
import { RunbooksNewFeatureCallout } from "./OperationsOverviewLayout";
import MarkdownDescription from "components/MarkdownDescription";
import { MenuItem, OverflowMenuItems } from "components/Menu/OverflowMenu";
import ListItem from "primitiveComponents/dataDisplay/ListItem";
import BranchSelector from "../BranchSelector/BranchSelector";
import { SpaceAwareHistory, useSpaceAwareHistory } from "components/Navigation/SpaceAwareHistory/useSpaceAwareHistory";
const styles = require("./styles.less");
import { match } from "react-router-dom";
import { useSelector } from "react-redux";
import { isVersionControlledProcess } from "../Process/Common/CommonProcessHelpers";

interface RunbooksLayoutState extends DataBaseComponentState {
    runbooks: ResourceCollection<RunbookResource>;
}

class RunbooksLayoutList extends List<RunbookResource> {}

interface RunbooksLayoutProps {
    projectContext: ProjectContextProps;
    isConfigurationAsCodeForRunbooksEnabled?: boolean;
    history: SpaceAwareHistory;
    match: match<{}>;
}

class RunbooksLayoutInternal extends DataBaseComponent<RunbooksLayoutProps, RunbooksLayoutState> {
    constructor(props: RunbooksLayoutProps) {
        super(props);
        this.state = {
            runbooks: null!,
        };
    }

    componentDidMount() {
        this.reload();
    }

    reload() {
        // noinspection JSIgnoredPromiseFromCall
        this.doBusyTask(async () => {
            const project = this.props.projectContext.state.model;
            const isVersionControlled = isVersionControlledProcess(project.IsVersionControlled, ProcessType.Runbook, !!this.props.isConfigurationAsCodeForRunbooksEnabled);
            const runbooks = isVersionControlled ? await this.props.projectContext.state.projectContextRepository.Runbooks.list({ skip: 0, take: 30 }) : await repository.Projects.getRunbooks(project, { skip: 0, take: 30 });
            this.setState({
                runbooks,
            });
        });
    }

    render() {
        const project = this.props.projectContext.state.model;

        const renderSectionControl = () => {
            const isVersionControlled = isVersionControlledProcess(project.IsVersionControlled, ProcessType.Runbook, !!this.props.isConfigurationAsCodeForRunbooksEnabled);
            if (isVersionControlled) {
                return (
                    <div className={styles.sectionControlContainer}>
                        <BranchSelector />
                        {addProcessButton}
                    </div>
                );
            }
            return addProcessButton;
        };

        const addProcessButton = (
            <PermissionCheck permission={Permission.RunbookEdit} project={project.Id} wildcard={true}>
                <OpenDialogButton label="Add Runbook" type={ActionButtonType.Primary}>
                    <AddRunbook
                        projectId={project.Id}
                        onProcessCreated={async (id, branch) => {
                            if (branch !== "no branch selected for non vcs runbooks") {
                                await this.props.projectContext.actions.onBranchSelected(project, branch.Name);
                            }
                            this.props.history.push(routeLinks.project(project.Slug).operations.runbook(id).root);
                        }}
                    />
                </OpenDialogButton>
            </PermissionCheck>
        );
        return (
            <PaperLayout busy={this.state.busy} errors={this.errors} title="Runbooks" sectionControl={renderSectionControl()}>
                <RunbooksNewFeatureCallout />
                {this.state.runbooks && this.state.runbooks.Items.length === 0 && <RunbooksOnboarding />}
                {this.state.runbooks && this.state.runbooks.Items.length > 0 && (
                    <RunbooksLayoutList
                        initialData={this.state.runbooks}
                        onRow={(item) => this.buildRow(item)}
                        match={this.props.match}
                        onRowRedirectUrl={(runbook: RunbookResource) => routeLinks.project(this.props.projectContext.state.model.Slug).operations.runbook(runbook.Id).root}
                        onRowAccessibleName={(runbook: RunbookResource) => `${runbook.Name}`}
                        onFilter={this.filter}
                        filterSearchEnabled={true}
                        apiSearchParams={["partialName"]}
                        filterHintText="Filter by name..."
                    />
                )}
            </PaperLayout>
        );
    }

    private filter(filter: string, resource: RunbookResource) {
        return !filter || filter.length === 0 || !resource || resource.Name.toLowerCase().includes(filter.toLowerCase());
    }

    private clonePermission(): PermissionCheckProps {
        const project = this.props.projectContext.state.model;
        return {
            permission: Permission.RunbookEdit,
            projectGroup: project && project.ProjectGroupId,
            wildcard: true,
        };
    }

    private buildRow(runbook: RunbookResource) {
        const project = this.props.projectContext.state.model;
        const runbookDescription = runbook.Description && <MarkdownDescription markup={runbook.Description} />;

        const CloneDialog = () => (
            <AddRunbook
                projectId={project.Id}
                onProcessCreated={async (id, branch) => {
                    if (branch === "no branch selected for non vcs runbooks" || branch.Name === this.props.projectContext.state.branch?.Name) {
                        // We don't await reloading here because we want the dialog to immediately close, and then the loading to happen on the form page
                        this.reload();
                    } else {
                        await this.props.projectContext.actions.onBranchSelected(this.props.projectContext.state.model, branch.Name);
                        this.props.history.push(routeLinks.projectBranch(this.props.projectContext.state.model.Slug, branch).runbooks);
                    }
                }}
                cloneId={runbook.Id}
            />
        );

        const overFlowActions: Array<MenuItem | MenuItem[]> = [];
        overFlowActions.push(OverflowMenuItems.dialogItem("Clone", <CloneDialog />, this.clonePermission()));
        overFlowActions.push([
            OverflowMenuItems.navItem("Audit Trail", routeLinks.configuration.runbookEventsForProject(project.Id, runbook.Id), null!, {
                permission: Permission.EventView,
                wildcard: true,
            }),
        ]);
        const runAction = isAllowed({ permission: Permission.RunbookRunCreate, project: project.Id, projectGroup: project.ProjectGroupId, wildcard: true }) ? (
            <NavigationButton label="Run..." href={routeLinks.project(project.Slug).operations.runbook(runbook.Id).runNow.root} type={NavigationButtonType.Secondary} />
        ) : null;
        return (
            <ListItem overflowMenuItems={overFlowActions} secondaryAction={runAction!}>
                <ListTitle>{runbook.Name}</ListTitle>
                {runbookDescription}
            </ListItem>
        );
    }
}

const isConfigurationAsCodeForRunbooksEnabledSelector = (state: GlobalState) => state.configurationArea.features.isConfigurationAsCodeForRunbooksEnabled;

const RunbooksLayout: React.FC = () => {
    const history = useSpaceAwareHistory();
    const projectContext = useProjectContext();
    const isConfigurationAsCodeForRunbooksEnabled = useSelector(isConfigurationAsCodeForRunbooksEnabledSelector);
    const match = useRouteMatch();
    if (!match) {
        // It should always be defined because we aren't passing any params to `useRouteMatch`
        throw new Error("The match object was not defined in RunbooksLayout.");
    }
    return <RunbooksLayoutInternal projectContext={projectContext} isConfigurationAsCodeForRunbooksEnabled={isConfigurationAsCodeForRunbooksEnabled} history={history} match={match} />;
};

export default RunbooksLayout;

import { FeaturesConfigurationResource, MultiTenancyStatusResource, SpaceResource } from "client/resources";
import { Reducer } from "redux";
import { combineReducers } from "redux";

enum ActionKeys {
    CONFIGURATION_FEATURES_FETCHED = "CONFIGURATION_FEATURES_FETCHED",
    CONFIGURATION_FEATURES_RESET = "CONFIGURATION_FEATURES_RESET",
    SPACE_MULTI_TENANCY_STATUS_FETCHED = "SPACE_MULTI_TENANCY_STATUS_FETCHED",
    SPACES_USER_ACCESSIBLE_SPACES_FETCHED = "SPACES_USER_ACCESSIBLE_SPACES_FETCHED",
    OTHER_ACTION = "__any_other_action_type__",
}

interface IConfigurationFeaturesFetchedAction {
    type: ActionKeys.CONFIGURATION_FEATURES_FETCHED;
    features: FeaturesConfigurationResource;
}

interface ISpaceMultiTenancyStatusFetchedAction {
    type: ActionKeys.SPACE_MULTI_TENANCY_STATUS_FETCHED;
    status: MultiTenancyStatusResource;
}

interface IUsersAccessibleSpacesFetchedAction {
    type: ActionKeys.SPACES_USER_ACCESSIBLE_SPACES_FETCHED;
    spaces: SpaceResource[];
}

interface IConfigurationFeaturesResetAction {
    type: ActionKeys.CONFIGURATION_FEATURES_RESET;
}

interface IOtherAction {
    type: ActionKeys.OTHER_ACTION;
}

type ActionTypes = IConfigurationFeaturesFetchedAction | ISpaceMultiTenancyStatusFetchedAction | IUsersAccessibleSpacesFetchedAction | IConfigurationFeaturesResetAction | IOtherAction;

export const configurationActions = {
    featuresFetched: (features: FeaturesConfigurationResource): IConfigurationFeaturesFetchedAction => ({
        type: ActionKeys.CONFIGURATION_FEATURES_FETCHED,
        features,
    }),
    featuresReset: (): IConfigurationFeaturesResetAction => ({
        type: ActionKeys.CONFIGURATION_FEATURES_RESET,
    }),
    spaceMultiTenancyStatusFetched: (status: MultiTenancyStatusResource): ISpaceMultiTenancyStatusFetchedAction => ({
        type: ActionKeys.SPACE_MULTI_TENANCY_STATUS_FETCHED,
        status,
    }),
    userAccessibleSpacesFetched: (userAccessibleSpaces: SpaceResource[]): IUsersAccessibleSpacesFetchedAction => ({
        type: ActionKeys.SPACES_USER_ACCESSIBLE_SPACES_FETCHED,
        spaces: userAccessibleSpaces,
    }),
};

export interface ConfigurationAreaState {
    readonly features: ConfigurationFeaturesState;
    readonly currentSpace: CurrentSpaceConfigurationState;
    readonly spaces: SpacesConfigurationState;
}

export interface SpacesConfigurationState {
    readonly usersAccessibleSpaces: SpaceResource[];
}

export interface CurrentSpaceConfigurationState {
    readonly isMultiTenancyEnabled: boolean;
}

export interface ConfigurationFeaturesState {
    readonly isCommunityActionTemplatesEnabled: boolean;
    readonly isBuiltInWorkerEnabled: boolean;
    readonly isHelpSidebarEnabled: boolean;
    readonly helpSidebarSupportLink: string;
    readonly isConfigurationAsCodeEnabled: boolean;
    readonly isConfigurationAsCodeForRunbooksEnabled: boolean;
    readonly isImportExportEnabled: boolean;
}

export interface ConfigurationFeaturesConfig {
    helpSidebarSupportLink: string;
}

const INITIAL_FEATURES_STATE: ConfigurationFeaturesState = {
    isBuiltInWorkerEnabled: false,
    isCommunityActionTemplatesEnabled: false,
    isHelpSidebarEnabled: false,
    helpSidebarSupportLink: "",
    isConfigurationAsCodeEnabled: false,
    isConfigurationAsCodeForRunbooksEnabled: false,
    isImportExportEnabled: false,
};

const featuresReducer: Reducer<ConfigurationFeaturesState> = (state: ConfigurationFeaturesState = INITIAL_FEATURES_STATE, action: ActionTypes): ConfigurationFeaturesState => {
    switch (action.type) {
        case ActionKeys.CONFIGURATION_FEATURES_FETCHED:
            if (action.features) {
                return {
                    isBuiltInWorkerEnabled: action.features.IsBuiltInWorkerEnabled,
                    isCommunityActionTemplatesEnabled: action.features.IsCommunityActionTemplatesEnabled,
                    isHelpSidebarEnabled: action.features.IsHelpSidebarEnabled,
                    helpSidebarSupportLink: action.features.HelpSidebarSupportLink ?? "",
                    isConfigurationAsCodeEnabled: action.features.IsConfigurationAsCodeEnabled,
                    isConfigurationAsCodeForRunbooksEnabled: action.features.IsConfigurationAsCodeForRunbooksEnabled,
                    isImportExportEnabled: action.features.IsImportExportEnabled,
                };
            }
            break;
        case ActionKeys.CONFIGURATION_FEATURES_RESET: {
            return INITIAL_FEATURES_STATE;
        }
    }

    return state;
};

const INITIAL_SPACES_STATE: CurrentSpaceConfigurationState = {
    isMultiTenancyEnabled: false,
};

const currentSpaceReducer: Reducer<CurrentSpaceConfigurationState> = (state: CurrentSpaceConfigurationState = INITIAL_SPACES_STATE, action: ActionTypes): CurrentSpaceConfigurationState => {
    switch (action.type) {
        case ActionKeys.SPACE_MULTI_TENANCY_STATUS_FETCHED:
            if (action.status) {
                return {
                    isMultiTenancyEnabled: action.status.Enabled,
                };
            }
            break;
    }

    return state;
};

const INITIAL_SPACE_STATE: SpacesConfigurationState = {
    usersAccessibleSpaces: [],
};

const spacesReducer: Reducer<SpacesConfigurationState> = (state: SpacesConfigurationState = INITIAL_SPACE_STATE, action: ActionTypes): SpacesConfigurationState => {
    switch (action.type) {
        case ActionKeys.SPACES_USER_ACCESSIBLE_SPACES_FETCHED:
            return {
                usersAccessibleSpaces: action.spaces || [],
            };
    }

    return state;
};

export const configurationArea = combineReducers<ConfigurationAreaState>({
    features: featuresReducer,
    currentSpace: currentSpaceReducer,
    spaces: spacesReducer,
});

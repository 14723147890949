import * as React from "react";
import { ProjectOrSummaryResource } from "client/resources";
import MultiSelect from "./MultiSelect";
import { FormFieldProps } from "components/form";
import { MissingChip, ProjectChip } from "components/Chips/index";
import { ChipIcon } from "components/Chips";
import Lookup from "../Lookup";
import { ComponentProps } from "utils/types";

interface ProjectMultiSelectProps extends FormFieldProps<string[]> {
    items: ProjectOrSummaryResource[];
    autoFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    accessibleName?: string;
}

const ProjectTypedMultiSelect = MultiSelect<ProjectOrSummaryResource>();
type RenderChip = ComponentProps<typeof ProjectTypedMultiSelect>["renderChip"];

const ProjectMultiSelect: React.FC<ProjectMultiSelectProps> = (props) => {
    const chipRenderer: RenderChip = (r, onRequestDelete: (event: object) => void) => {
        return (
            <Lookup
                lookupCollection={props.items}
                lookupId={r.Id}
                getIdFromElement={(element) => element.Id}
                render={(item) => <ProjectChip onRequestDelete={onRequestDelete} deleteButtonAccessibleName={`Delete ${item.Name}`} project={item} />}
                renderFallback={<MissingChip type={ChipIcon.Project} lookupId={r.Id} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete} />}
            />
        );
    };
    return <ProjectTypedMultiSelect fieldName="projects" renderChip={chipRenderer} accessibleName={props.accessibleName} {...props} items={props.items} />;
};

export default ProjectMultiSelect;

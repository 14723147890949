import * as React from "react";
import AreaTitle from "components/AreaTitle";
import routeLinks from "routeLinks";
import { ExpandableFormSection, RadioButton, RadioButtonGroup, required, Sensitive, Summary, SummaryNode, Text } from "components/form";
import DataBaseComponent, { DataBaseComponentState } from "components/DataBaseComponent";
import { ProjectSummaryResource, SensitiveValue, SpaceResource } from "client/resources";
import { repository, session } from "clientInstance";
import { ProjectMultiSelect } from "components/MultiSelect";
import PaperLayout from "components/PaperLayout";
import { projectChipList } from "components/Chips";
import ActionButton, { ActionButtonType } from "components/Button";
import InternalRedirect from "components/Navigation/InternalRedirect";
import Callout, { CalloutType } from "primitiveComponents/dataDisplay/Callout";
import DrawerWrapperLayout from "components/Drawer/DrawerWrapperLayout";
import ExpansionButtons from "../../../../components/form/Sections/ExpansionButtons";
const styles = require("./style.less");

interface ExportProjectsState extends DataBaseComponentState {
    importExportIsEnabled: boolean;
    projects: ProjectSummaryResource[];
    space?: SpaceResource;
    includedProjectIds: string[];
    name: string;
    includeAllProjectData: boolean;
    password: SensitiveValue;
    redirectPath?: string;
}

class ExportProjectsInternal extends DataBaseComponent<{}, ExportProjectsState> {
    constructor(props: {}) {
        super(props);

        this.state = {
            importExportIsEnabled: false,
            projects: [],
            name: "",
            includedProjectIds: [],
            includeAllProjectData: false,
            password: { HasValue: false },
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            if (!repository.spaceId) {
                throw new Error("Attempted to render ImportExportTasks in a system context. This should never happen.");
            }
            const [projects, space, featuresConfiguration] = await Promise.all([repository.Projects.summaries(), repository.Spaces.get(repository.spaceId), repository.FeaturesConfiguration.get()]);

            this.setState({
                projects,
                space,
                importExportIsEnabled: featuresConfiguration.IsImportExportEnabled,
            });
        });
    }

    isSpaceManager(): boolean {
        if (!session.currentPermissions) {
            throw new Error("Attempted to access the current user's permissions, but they weren't found. This should never happen.");
        }
        return this.state.space !== undefined && session.currentPermissions.isSpaceManager(this.state.space);
    }

    doExport = async () => {
        await this.doBusyTask(async () => {
            const projectExportResource = await repository.ImportExport.export({ IncludedProjectIds: this.state.includedProjectIds, Name: this.state.name, IncludeAllProjectData: this.state.includeAllProjectData, Password: this.state.password });

            this.setState({
                redirectPath: routeLinks.task(projectExportResource.TaskId).root,
            });
        });
    };

    projectsChanged = (includedProjectIds: string[]) => {
        if (!this.state.projects) return;
        const name = includedProjectIds.map((id) => `${this.state.projects.find((p) => p.Id === id)?.Name ?? ""}`).join(", ");
        this.setState({ includedProjectIds, name });
    };

    render() {
        if (this.state.redirectPath) {
            return <InternalRedirect to={this.state.redirectPath} push={true} />;
        }

        return (
            <main id="maincontent">
                <AreaTitle link={routeLinks.importExport.root} title="Import/Export" breadcrumbTitle="Projects" breadcrumbPath={routeLinks.projects.root} />
                <DrawerWrapperLayout>
                    <div className={styles.paperContainer}>
                        <PaperLayout
                            title="Export Projects"
                            busy={this.state.busy}
                            errors={this.errors}
                            sectionControl={
                                <ActionButton
                                    type={ActionButtonType.Primary}
                                    label="Export"
                                    onClick={() => this.doExport()}
                                    disabled={this.state.busy || !this.state.password || !this.state.password.NewValue || this.state.password.NewValue === "" || this.state.includedProjectIds.length === 0 || this.state.name === ""}
                                />
                            }
                        >
                            {this.state.space && !this.isSpaceManager() && (
                                <Callout title="Permissions" type={CalloutType.Danger}>
                                    Only Space Managers can perform an export or import.
                                </Callout>
                            )}
                            {this.state.space && this.isSpaceManager() && !this.state.importExportIsEnabled && (
                                <Callout title="Feature Toggle" type={CalloutType.Information}>
                                    The Import/Export feature is not currently enabled.
                                </Callout>
                            )}
                            {this.state.space && this.isSpaceManager() && this.state.importExportIsEnabled && (
                                <>
                                    <ExpansionButtons errors={this.errors} />
                                    <ExpandableFormSection errorKey="includedProjectIds" title="Projects" summary={this.projectsSummary()} help="Select the projects to be exported." isExpandedByDefault={true}>
                                        <ProjectMultiSelect onChange={(includedProjectIds) => this.projectsChanged(includedProjectIds)} value={this.state.includedProjectIds} items={this.state.projects} accessibleName="Projects" />
                                    </ExpandableFormSection>
                                    <ExpandableFormSection errorKey="name" title="Export Name" summary={this.nameSummary()} help="Give the export a name." isExpandedByDefault={true}>
                                        <Text value={this.state.name} onChange={(name) => this.setState({ name })} label="Export name" validate={required("Please enter a name for the export")} />
                                    </ExpandableFormSection>
                                    <ExpandableFormSection errorKey="includeAllProjectData" title="Included data" summary={this.includedDataSummary()} help="Which project data should be exported." isExpandedByDefault={true}>
                                        <RadioButtonGroup onChange={(includeAllProjectData) => this.setState({ includeAllProjectData })} value={this.state.includeAllProjectData}>
                                            <RadioButton value={true} label="All project data, including history" isDefault={true} disabled={true} />
                                            <RadioButton value={false} label="Only project structure" disabled={true} />
                                        </RadioButtonGroup>
                                    </ExpandableFormSection>
                                    <ExpandableFormSection errorKey="password" title="Password" summary={this.passwordSummary()} isExpandedByDefault={true} help="Enter the password to protect sensitive values in the exported data.">
                                        <Sensitive label="Password" value={this.state.password} onChange={(password) => this.setState({ password })} />
                                    </ExpandableFormSection>
                                </>
                            )}
                        </PaperLayout>
                    </div>
                </DrawerWrapperLayout>
            </main>
        );
    }

    projectsSummary(): SummaryNode {
        return this.state.includedProjectIds && this.state.includedProjectIds.length ? Summary.summary(<div>Export project(s) {projectChipList(this.state.projects, this.state.includedProjectIds)}</div>) : Summary.default("No projects selected");
    }

    nameSummary(): SummaryNode {
        return this.state.name !== "" ? Summary.summary(<div>{this.state.name}</div>) : Summary.default("No name entered");
    }

    includedDataSummary(): SummaryNode {
        return this.state.includeAllProjectData ? Summary.summary(<div>All project data, including history will be exported</div>) : Summary.default("Only projects structure will be exported");
    }

    passwordSummary(): SummaryNode {
        return this.state.password && this.state.password.NewValue && this.state.password.NewValue !== "" ? Summary.summary(<div>Password has been provided</div>) : Summary.default("No password has been set");
    }
}

const ExportProjectsPage: React.FC = () => {
    return <ExportProjectsInternal />;
};

export default ExportProjectsPage;

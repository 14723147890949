import * as React from "react";
import * as _ from "lodash";
import { default as pluginRegistry, FeaturePlugin } from "../Actions/pluginRegistry";
import { ActionEditorProps } from "../ActionEditor/ActionEditor";
import { UnstructuredFormSection, Note, FormSectionHeading } from "../form";
import { WithActionScopeInjectedProps } from "components/Actions/withActionScope";
const styles = require("./style.less");

interface FeatureEditorPropsInternal extends ActionEditorProps {
    enabledFeatures: string;
    openFeaturesElement?: JSX.Element;
}

type FeatureEditorProps = FeatureEditorPropsInternal & WithActionScopeInjectedProps;

interface FeatureEditorState {
    plugins: FeaturePlugin[];
}

export default class FeatureEditor extends React.Component<FeatureEditorProps, FeatureEditorState> {
    constructor(props: FeatureEditorProps) {
        super(props);
        this.state = {
            plugins: [],
        };
    }
    componentDidMount() {
        this.refreshPlugins(this.props.enabledFeatures);
    }

    componentWillReceiveProps(newProps: FeatureEditorProps) {
        if (newProps.enabledFeatures !== this.props.enabledFeatures) {
            this.refreshPlugins(newProps.enabledFeatures);
        }
    }

    render() {
        return (
            <div>
                {this.state.plugins.map((plugin) => {
                    return (
                        <>
                            <FormSectionHeading title={plugin.title} />
                            <plugin.edit
                                key={"featureeditor-" + plugin.featureName}
                                plugin={this.props.plugin}
                                projectId={this.props.projectId}
                                localNames={this.props.localNames}
                                properties={this.props.properties}
                                packages={this.props.packages}
                                setPackages={this.props.setPackages}
                                doBusyTask={this.props.doBusyTask}
                                setProperties={this.props.setProperties}
                                getFieldError={this.props.getFieldError}
                                errors={this.props.errors}
                                expandedByDefault={this.props.expandedByDefault}
                            />
                        </>
                    );
                })}
                <UnstructuredFormSection>
                    <div className={styles.featuresActionContainer}>
                        {this.props.openFeaturesElement && <div>{this.props.openFeaturesElement}</div>}
                        <Note>You can add or manage additional features used by this step.</Note>
                    </div>
                </UnstructuredFormSection>
            </div>
        );
    }

    private refreshPlugins(enabledFeatures: string) {
        const featureNames = (enabledFeatures || "").split(",");
        const allFeatures = pluginRegistry.getAllFeatures(this.props.scope);
        const plugins = _.chain(allFeatures)
            .filter((feature) => featureNames.includes(feature.featureName))
            .sortBy((feature) => feature.priority)
            .value();
        this.setState({ plugins });
    }
}

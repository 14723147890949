/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { createContext } from "react";
import { repository, client } from "clientInstance";
import { ProjectResource, ProjectSummary, VcsBranchResource } from "client/resources";
import { DoBusyTask } from "components/DataBaseComponent";
import { useRequiredContext } from "hooks";
import { RecentProjects } from "utils/RecentProjects/RecentProjects";
import Logger from "client/logger";
import { BranchSpecifier, ShouldUseDefaultBranch, UseDefaultBranch } from "../components/ProjectsRoutes/BranchSpecifier";
import { lastAccessedBranch } from "./LastAccessedBranch";
import ProjectContextRepository from "client/repositories/projectContextRepository";

type ProjectContextProviderProps = {
    doBusyTask: DoBusyTask;
    projectIdOrSlug: string;
    branch: BranchSpecifier;
    children: (props: ProjectContextProps) => React.ReactNode;
};

export interface ProjectContextState {
    model: Readonly<ProjectResource>;
    summary: Readonly<ProjectSummary>;
    branch: Readonly<VcsBranchResource> | undefined;
    projectContextRepository: ProjectContextRepository;
}

export interface ProjectContextActions extends ProjectLayoutActions {
    refreshModel: () => Promise<boolean>;
    refreshAndGetModel: () => Promise<ProjectContextState["model"]>;
}

export type ProjectContextProps = ReturnType<typeof useProjectLayoutSetup>;
export const ProjectContext = createContext<ProjectContextProps | undefined>(undefined);

function modifyStateForWhenBranchNotLoaded(value: ProjectContextProps, branch: BranchSpecifier): ProjectContextProps {
    // Switching branches, we load the new branch resource asynchronously whereas the page (e.g. list of runbooks) tries
    // to reload its data. As the branch has not yet been loaded, runbooks get loaded from the old branch. We explicitly
    // set the branch to undefined here to force ProjectLayout to not render content till the branch data has been loaded.
    if (branch !== UseDefaultBranch && branch !== value.state.branch?.Name) {
        return {
            ...value,
            state: {
                ...value.state,
                branch: undefined,
            },
        };
    }
    return value;
}

const ProjectContextProvider: React.FC<ProjectContextProviderProps> = (props) => {
    const value = useProjectLayoutSetup(props.doBusyTask, props.projectIdOrSlug, props.branch);
    return <ProjectContext.Provider value={value}>{props.children(modifyStateForWhenBranchNotLoaded(value, props.branch))}</ProjectContext.Provider>;
};

export const useProjectContext = () => {
    return useRequiredContext(ProjectContext, "Project");
};

export const useOptionalProjectContext = () => {
    return React.useContext(ProjectContext);
};

const getStateUpdaters = (setState: React.Dispatch<React.SetStateAction<ProjectContextState>>) => {
    return {
        onProjectUpdated: async (project: ProjectResource) => {
            const summary = await repository.Projects.getSummary(project);
            setState((current) => ({ ...current, model: project, summary }));
        },
        onVersionControlEnabled: async (project: ProjectResource) => {
            const branch = await lastAccessedBranch.save(project, project.VersionControlSettings.DefaultBranch);
            setState((current) => ({ ...current, branch: branch, projectContextRepository: new ProjectContextRepository(client, project, branch) }));
        },
        onBranchSelected: async (project: ProjectResource, branchName: string) => {
            const branch = await lastAccessedBranch.save(project, branchName);
            setState((current) => ({ ...current, branch: branch, projectContextRepository: new ProjectContextRepository(client, project, branch) }));
        },
    };
};

const useProjectLayoutState = () => {
    return React.useState<ProjectContextState>({
        model: null!,
        summary: null!,
        projectContextRepository: null!,
        branch: undefined,
    });
};

const invokeBusyWithResponse = async <T extends unknown>(action: () => Promise<T>, doBusyTask: DoBusyTask) => {
    const result = action();
    await doBusyTask(() => result);
    return result;
};

const useSaveRecentAccessedProjectIdEffect = (projectId: string | null) => {
    React.useEffect(() => {
        if (projectId) {
            RecentProjects.getInstance().UpdateAccessedProjectIntoLocalStorage(projectId).catch(Logger.log);
        }
    }, [projectId]);
};

type ProjectLayoutActions = ReturnType<typeof getStateUpdaters>;

const subscribeKey = "ProjectContextState";

const useProjectLayoutSetup = (doBusyTask: DoBusyTask, projectIdOrSlug: string, branchSpecifier: BranchSpecifier) => {
    const [state, setState] = useProjectLayoutState();
    const updaters = React.useMemo(() => getStateUpdaters(setState), [setState]);

    const refreshAndGetModel = React.useCallback(
        () =>
            invokeBusyWithResponse(async () => {
                const project = await repository.Projects.get(projectIdOrSlug);

                if (project.IsVersionControlled) {
                    const branchResource = ShouldUseDefaultBranch(branchSpecifier) ? await lastAccessedBranch.get(project) : await lastAccessedBranch.save(project, branchSpecifier);
                    setState((current) => ({
                        ...current,
                        branch: branchResource,
                        projectContextRepository: new ProjectContextRepository(client, project, branchResource),
                    }));
                } else {
                    setState((current) => ({
                        ...current,
                        branch: undefined,
                        projectContextRepository: new ProjectContextRepository(client, project, undefined),
                    }));
                }

                await updaters.onProjectUpdated(project);
                return project;
            }, doBusyTask),
        [doBusyTask, setState, updaters, projectIdOrSlug, branchSpecifier]
    );

    const refreshModel = React.useCallback(() => refreshAndGetModel().then((x) => true), [refreshAndGetModel]);

    React.useEffect(() => {
        repository.Projects.subscribeToDataModifications(subscribeKey, (project) => {
            setState((previous) => {
                if (previous.model && previous.model.Id === project.Id) {
                    return { ...previous, model: project };
                } else {
                    return previous;
                }
            });
        });

        return () => {
            repository.Projects.unsubscribeFromDataModifications(subscribeKey);
        };
    }, [setState]);

    React.useEffect(() => {
        // eslint-disable-next-line: no-floating-promises
        refreshAndGetModel();
    }, [refreshAndGetModel]);

    useSaveRecentAccessedProjectIdEffect(state.model && state.model.Id);

    const supportedActions = {
        refreshAndGetModel,
        refreshModel,
        ...updaters,
    };

    return {
        actions: supportedActions,
        state,
        setState,
    };
};

type ProjectContextConsumerProps = Parameters<typeof ProjectContext.Consumer>[0];
const ProjectContextConsumer: React.SFC<ProjectContextConsumerProps> = ({ children }) => {
    const context = useProjectContext();
    return <React.Fragment>{children(context)}</React.Fragment>;
};

export { ProjectContextProvider, ProjectContextConsumer };

import * as React from "react";
import { session, repository } from "clientInstance";
import { Text, MarkdownEditor, Select, required, Checkbox } from "components/form";
import { LifecycleResource, ProjectGroupResource, EnvironmentResource, Permission, ProjectSummaryResource, ProjectResource } from "client/resources";
import { ActionButton, ActionButtonType } from "components/Button";
import Note from "../../../../primitiveComponents/form/Note/Note";
import InternalLink from "../../../../components/Navigation/InternalLink/InternalLink";
import LifecycleMap from "areas/library/components/Lifecycle/LifecycleMap";
import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import routeLinks from "../../../../routeLinks";
import SaveDialogLayout from "components/DialogLayout/SaveDialogLayout";
import Callout, { CalloutType } from "primitiveComponents/dataDisplay/Callout";
import { ResourcesById } from "client/repositories/basicRepository";
import PermissionCheck, { isAllowed } from "components/PermissionCheck/PermissionCheck";
import useConfigurationAsCodeFeatureFlag from "areas/configuration/hooks/useConfigurationAsCodeFeatureFlag";
const styles = require("./style.less");

export interface AddProjectDialogProps {
    groupId?: string;
    projectCreated: (project: ProjectResource, option?: ProjectCreatedOption) => void;
}

export type ProjectCreatedOption = {
    vcsRedirect?: boolean;
};

type InternalAddProjectDialogProps = AddProjectDialogProps & {
    isConfigAsCodeEnabled: boolean;
};

interface AddProjectDialogState extends DataBaseComponentState {
    vcsRedirect: boolean;
    name: string;
    description: string;
    newProjectId: string | null;
    projectGroupId: string | null;
    selectedLifecycle: LifecycleResource | null;
    projectGroups: ProjectGroupResource[];
    lifecycles: LifecycleResource[] | null;
    environmentsById: ResourcesById<EnvironmentResource>;
    otherProjects: ProjectSummaryResource[];
    showLifecycleMap: boolean;
    showAdvanced: boolean;
}

class AddProjectInternal extends DataBaseComponent<InternalAddProjectDialogProps, AddProjectDialogState> {
    constructor(props: InternalAddProjectDialogProps) {
        super(props);
        this.state = {
            vcsRedirect: false,
            name: "",
            description: "",
            projectGroupId: null,
            selectedLifecycle: null,
            projectGroups: [],
            lifecycles: null,
            environmentsById: {},
            newProjectId: null,
            otherProjects: [],
            showLifecycleMap: false,
            showAdvanced: false,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const [projectGroups, lifecycles, environmentsById] = await Promise.all<ProjectGroupResource[], LifecycleResource[], ResourcesById<EnvironmentResource>>([
                repository.ProjectGroups.all(),
                isAllowed({ permission: Permission.LifecycleView }) ? repository.Lifecycles.all() : Promise.resolve([]),
                repository.Environments.allById(),
            ]);

            const lifecyclePreviews = await Promise.all(lifecycles.map((l) => repository.Lifecycles.preview(l)));
            const lifecycle = lifecyclePreviews.find((x: LifecycleResource) => x.Name === "Default Lifecycle");
            const projectGroup = projectGroups.find((x) => x.Name === "All Projects" || x.Name === "Default Project Group");
            const projectGroupId = this.props.groupId || (projectGroup ? projectGroup.Id : projectGroups[0].Id);

            this.setState({
                lifecycles: lifecyclePreviews,
                projectGroups,
                environmentsById,
                selectedLifecycle: lifecycle || lifecyclePreviews[0],
                projectGroupId,
            });
        });
    }

    async save() {
        await this.doBusyTask(async () => {
            if (!this.state.projectGroupId) {
                this.setValidationErrors("You need to select a project group");
                return false;
            }

            if (!this.state.selectedLifecycle) {
                this.setValidationErrors("You need to select a lifecycle");
                return false;
            }

            const result = await repository.Projects.create({
                Name: this.state.name,
                Description: this.state.description,
                ProjectGroupId: this.state.projectGroupId,
                LifecycleId: this.state.selectedLifecycle.Id,
            });

            // refresh permissions to include the new project
            if (session.currentUser) {
                const permissionSet = await repository.UserPermissions.getAllPermissions(session.currentUser, true);
                session.refreshPermissions(permissionSet);
            }
            this.props.projectCreated(result, { vcsRedirect: this.state.vcsRedirect });
            return true;
        });

        return false;
    }

    render() {
        if (!this.state.lifecycles) {
            return <SaveDialogLayout title="Add New Project" busy={true} errors={this.errors} onSaveClick={() => this.save()} />;
        }

        const showAdvancedButton = this.state.lifecycles.length <= 1;

        return (
            <SaveDialogLayout
                title="Add New Project"
                busy={this.state.busy}
                errors={this.errors}
                saveButtonLabel={this.state.vcsRedirect ? "Save and configure VCS" : undefined}
                saveButtonDisabled={this.state.name.length === 0}
                onSaveClick={() => this.save()}
            >
                <PermissionCheck
                    permission={Permission.LifecycleView}
                    alternate={
                        <Callout type={CalloutType.Information} title={"Permission required"}>
                            The {Permission.LifecycleView} permission is required to create a project
                        </Callout>
                    }
                >
                    {!this.state.busy && this.state.lifecycles.length === 0 && (
                        <Callout type={CalloutType.Danger} title="No lifecycles Configured">
                            <InternalLink to={routeLinks.library.lifecycles}>Configure your lifecycles</InternalLink> before setting up a project.
                        </Callout>
                    )}
                    {!this.state.busy && Object.keys(this.state.environmentsById).length === 0 && (
                        <Callout type={CalloutType.Warning} title="No Environments Configured">
                            Please consider <InternalLink to={routeLinks.infrastructure.overview}>configuring your infrastructure</InternalLink> before setting up a project.
                        </Callout>
                    )}
                    {this.props.isConfigAsCodeEnabled && (
                        <Checkbox
                            label="Use version control for this project"
                            value={this.state.vcsRedirect}
                            onChange={() => {
                                this.setState({ vcsRedirect: !this.state.vcsRedirect });
                            }}
                            note={"If set, you will be redirected to the VCS Settings page to configure version control when you save."}
                        />
                    )}
                    <Text label="New project name" value={this.state.name} onChange={(name) => this.setState({ name })} validate={required("Please enter a project name")} autoFocus={true} />
                    {showAdvancedButton && (
                        <ActionButton
                            label={this.state.showAdvanced ? "Hide Advanced" : "Show Advanced"}
                            type={ActionButtonType.Ternary}
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({ showAdvanced: !this.state.showAdvanced });
                            }}
                        />
                    )}
                    {(!showAdvancedButton || this.state.showAdvanced) && (
                        <React.Fragment>
                            <MarkdownEditor label="Project description" value={this.state.description} onChange={this.handleDescriptionChanged} />
                            <Select
                                value={this.state.projectGroupId || undefined}
                                onChange={(projectGroupId) => this.setState({ projectGroupId: projectGroupId || null })}
                                items={this.state.projectGroups.map((pg) => ({ value: pg.Id, text: pg.Name }))}
                                label="Project group"
                            />
                            <Select value={this.state.selectedLifecycle ? this.state.selectedLifecycle.Id : ""} onChange={this.handleLifeCycleChange} items={this.state.lifecycles.map((l) => ({ value: l.Id, text: l.Name }))} label="Lifecycle" />
                            <Note>
                                Lifecycles determine which environments the project can be deployed to, and the promotion rules between those environments.{" "}
                                <InternalLink to={routeLinks.library.lifecycles} openInSelf={false}>
                                    Create or modify lifecycles
                                </InternalLink>
                            </Note>
                            {Object.keys(this.state.environmentsById).length > 0 && (
                                <ActionButton
                                    type={ActionButtonType.Ternary}
                                    label={this.state.showLifecycleMap ? "Hide lifecycle" : "Show lifecycle"}
                                    onClick={() => {
                                        this.setState({ showLifecycleMap: !this.state.showLifecycleMap });
                                    }}
                                />
                            )}
                            {this.state.showLifecycleMap && (
                                <div>
                                    {this.state.selectedLifecycle && (
                                        <div className={styles.lifecycleMap}>
                                            <LifecycleMap environmentsById={this.state.environmentsById} lifecyclePreview={this.state.selectedLifecycle} />
                                        </div>
                                    )}
                                </div>
                            )}
                        </React.Fragment>
                    )}
                </PermissionCheck>
            </SaveDialogLayout>
        );
    }

    private handleLifeCycleChange = (value: string | undefined) => {
        const lifecycles = this.state.lifecycles?.filter((l) => l.Id === value) || [];
        this.setState({ selectedLifecycle: lifecycles.length > 0 ? lifecycles[0] : null });
    };

    private handleDescriptionChanged = (description: string) => {
        this.setState({ description });
    };
}

const AddProject: React.FC<AddProjectDialogProps> = (props) => {
    const isConfigAsCodeEnabled = useConfigurationAsCodeFeatureFlag();
    return <AddProjectInternal {...props} isConfigAsCodeEnabled={isConfigAsCodeEnabled} />;
};

export default AddProject;

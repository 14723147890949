/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import { ProjectResource, Permission, SensitiveValue, ProcessType, VersionControlCompatibilityResponse } from "client/resources";
import { repository } from "clientInstance";
import FormBaseComponent, { OptionalFormBaseComponentState } from "components/FormBaseComponent";
import Sensitive from "components/form/Sensitive/Sensitive";
import { Text, ExpandableFormSection, Summary, SummaryNode, UnstructuredFormSection, Note } from "components/form";
import { required } from "components/form/Validators";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { PermissionCheckProps } from "components/PermissionCheck/PermissionCheck";
import { ProjectRouteParams } from "areas/projects/components/ProjectsRoutes/ProjectRouteParams";
import Callout, { CalloutType } from "primitiveComponents/dataDisplay/Callout/Callout";
import TransitionAnimation from "components/TransitionAnimation/TransitionAnimation";
import * as _ from "lodash";
import { withProjectContext, WithProjectContextInjectedProps } from "../../../context";
import configurationSelectors from "areas/configuration/reducers/selectors";
import CommitDialog from "./CommitDialog";
import TestConnectionButton from "./TestConnectionButton";
import ActionButton, { ActionButtonType } from "components/Button";
import FormPaperLayout from "components/FormPaperLayout";
import { GitChip } from "./GitChip";
import { branchNameToShowByDefault, getBasePathToShowByDefault } from "client/resources/versionControlledResource";
import { PrimaryActionProps } from "components/FormPaperLayout/FormPaperLayout";
import { isVersionControlledProcess } from "../../Process/Common/CommonProcessHelpers";
import { CommitMessageWithDetails, getFormattedCommitMessage } from "areas/projects/components/VersionControl/CommitMessageWithDetails";
import Markdown from "components/Markdown";
import LetsEncrypt from "areas/configuration/components/LetsEncrypt/LetsEncrypt";

interface EarlyAccessWarningSectionProps {
    isConfigurationAsCodeEnabled: boolean;
    isVersionControlled: boolean;
}

const EarlyAccessWarningSection: React.FC<EarlyAccessWarningSectionProps> = ({ isConfigurationAsCodeEnabled, isVersionControlled }) => {
    return (
        <UnstructuredFormSection stretchContent={true}>
            {isConfigurationAsCodeEnabled ? (
                <Callout type={CalloutType.Warning} title={"Early access feature"}>
                    {isVersionControlled ? (
                        <div>Version control is configured. You can start creating branches of this project's deployment process.</div>
                    ) : (
                        <div>Connect your Git Repository to start creating branches of this project's deployment process.</div>
                    )}
                </Callout>
            ) : (
                <Callout type={CalloutType.Warning} title={"This feature is currently disabled"} />
            )}
        </UnstructuredFormSection>
    );
};

interface UnsupportedFeaturesCalloutProps {
    vcsCompatibilityReport: VersionControlCompatibilityResponse;
}

const UnsupportedFeaturesSection: React.FC<UnsupportedFeaturesCalloutProps> = ({ vcsCompatibilityReport }) => {
    if (vcsCompatibilityReport.Errors.length < 1 && vcsCompatibilityReport.Warnings.length < 1 && vcsCompatibilityReport.Notices.length < 1) {
        return null;
    }

    const callouts: React.ReactNodeArray = [];
    callouts.push(
        <div>
            <h3>Version Control Compatibility</h3>
            <Note>We've run a check to see whether this project is capable of suporting version control and have listed some things for you to take into consideration below.</Note>
        </div>
    );
    if (vcsCompatibilityReport.Errors.length > 0) {
        callouts.push(
            <p>
                <Callout type={CalloutType.Danger} title={"Errors"}>
                    {vcsCompatibilityReport.Errors.map((x) => (
                        <Markdown markup={x} />
                    ))}
                </Callout>
            </p>
        );
    }
    if (vcsCompatibilityReport.Warnings.length > 0) {
        callouts.push(
            <p>
                <Callout type={CalloutType.Warning} title={"Warnings"}>
                    {vcsCompatibilityReport.Warnings.map((x) => (
                        <Markdown markup={x} />
                    ))}
                </Callout>
            </p>
        );
    }
    if (vcsCompatibilityReport.Notices.length > 0) {
        callouts.push(
            <p>
                <Callout type={CalloutType.Information} title={"Notices"}>
                    {vcsCompatibilityReport.Notices.map((x) => (
                        <Markdown markup={x} />
                    ))}
                </Callout>
            </p>
        );
    }
    return <UnstructuredFormSection>{callouts}</UnstructuredFormSection>;
};

export interface VersionControlSettingsModel {
    enabled: boolean;
    url: string;
    username: string;
    password: SensitiveValue;
    defaultBranch: string;
    basePath: string;
}

interface VersionControlSettingsState extends OptionalFormBaseComponentState<VersionControlSettingsModel> {
    project?: ProjectResource;
    commitDialogActive: boolean;
    commitMessage: CommitMessageWithDetails;
    vcsCompatibilityReport: VersionControlCompatibilityResponse | null;
    saveForm: () => Promise<boolean>;
}

interface GlobalConnectedProps {
    isConfigurationAsCodeEnabled: boolean;
}

type ProjectSettingsProps = RouteComponentProps<ProjectRouteParams>;
type Props = ProjectSettingsProps & GlobalConnectedProps & WithProjectContextInjectedProps;

const defaultCommitMessage = "Initial commit of deployment process";

class VersionControlSettingsInternal extends FormBaseComponent<Props, VersionControlSettingsState, VersionControlSettingsModel> {
    constructor(props: Props) {
        super(props);
        this.state = {
            commitDialogActive: false,
            commitMessage: { summary: "", details: "" },
            vcsCompatibilityReport: null,
            saveForm: () => Promise.resolve(false),
        };
    }

    resetVersionTemplate = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState((state) => {
            const nextModel = {
                ...state.model,
            };
            return {
                model: nextModel,
            };
        });
    };

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const project = this.props.projectContext.state.model;
            const isVersionControlled = isVersionControlledProcess(project.IsVersionControlled, ProcessType.Deployment, false);
            const vcsCompatibilityReport = !isVersionControlled ? await repository.Projects.vcsCompatibilityReport(project) : null;
            this.setState({
                project,
                vcsCompatibilityReport,
                model: this.buildModel(project),
                cleanModel: this.buildModel(project),
            });
        });
    }

    buildModel(project: ProjectResource): VersionControlSettingsModel {
        const model: VersionControlSettingsModel = {
            enabled: project.IsVersionControlled,
            url: project.VersionControlSettings.Url,
            username: project.VersionControlSettings.Username,
            password: project.VersionControlSettings.Password,
            defaultBranch: project.VersionControlSettings.DefaultBranch || branchNameToShowByDefault,
            basePath: project.VersionControlSettings.BasePath || getBasePathToShowByDefault(project.Name),
        };
        return model;
    }

    handleSaveClick = async () => {
        if (!this.state.model || !this.state.project) {
            throw Error("Tried to save with an empty model");
        }

        const model = this.state.model;
        const project: ProjectResource = {
            ...this.state.project,
            IsVersionControlled: model.enabled,
            VersionControlSettings: {
                Url: model.url,
                Username: model.username,
                Password: model.password,
                DefaultBranch: model.defaultBranch,
                BasePath: model.basePath,
            },
        };

        await this.doBusyTask(async () => {
            if (project.IsVersionControlled) {
                await this.saveProject(project);
            } else {
                const formattedMessage = getFormattedCommitMessage(this.state.commitMessage, defaultCommitMessage);
                await this.convertProjectToVcs(project, formattedMessage);
            }
        });
    };

    render() {
        const isExpanded = this.state.model && !this.state.model.url;
        const formFields = this.props.isConfigurationAsCodeEnabled && this.state.model && this.state.project && (
            <React.Fragment>
                <TransitionAnimation>
                    {this.state.project.IsDisabled && (
                        <UnstructuredFormSection stretchContent={true}>
                            <Callout type={CalloutType.Warning} title={"This project is currently disabled"} />
                        </UnstructuredFormSection>
                    )}
                    <ExpandableFormSection errorKey="url" title="Git Repository" summary={this.summary()} help="In which Git repository do you want to store this project's deployment process?" isExpandedByDefault={isExpanded}>
                        <Text
                            key="url"
                            value={this.state.model.url}
                            onChange={(url) => this.setModelState({ url })}
                            label="URL"
                            error={this.getFieldError("url")}
                            validate={required("Enter a Git repository URL.")}
                            autoFocus={true}
                            disabled={!!this.state.busy}
                        />
                        <Note>Enter an existing empty (and initialized) git repository URL. E.g. https://...git</Note>
                    </ExpandableFormSection>
                    <ExpandableFormSection errorKey="username" title="Authentication" summary={this.authSummary()} help="Choose a method to authenticate with the above Git repository" isExpandedByDefault={isExpanded}>
                        <Text
                            key="username"
                            value={this.state.model.username}
                            onChange={(username) => this.setModelState({ username })}
                            label="Username"
                            error={this.getFieldError("username")}
                            validate={required("Enter authentication details.")}
                            disabled={!!this.state.busy}
                        />
                        <Sensitive key="password" value={this.state.model.password} onChange={(password) => this.setModelState({ password })} label="Password" error={this.getFieldError("password")} disabled={!!this.state.busy} />
                        <Note>This may be a Personal Access Token, depending on which provider you're using.</Note>
                    </ExpandableFormSection>
                    <ExpandableFormSection errorKey="defaultBranch" title="Default Branch Name" summary={this.branchSummary()} help="Provide the name of the default branch" isExpandedByDefault={isExpanded}>
                        <Text
                            key="defaultBranch"
                            value={this.state.model.defaultBranch}
                            onChange={(defaultBranch) => this.setModelState({ defaultBranch })}
                            label="Default Branch"
                            error={this.getFieldError("defaultBranch")}
                            validate={required("Enter a default branch.")}
                            disabled={!!this.state.busy}
                        />
                    </ExpandableFormSection>
                    <ExpandableFormSection errorKey="basePath" title="Base Path" summary={this.basePathSummary()} help="Base path in the repository for Octopus project files (e.g. deployment processes)" isExpandedByDefault={isExpanded}>
                        <Text
                            key="basePath"
                            value={this.state.model.basePath}
                            onChange={(basePath) => this.setModelState({ basePath })}
                            label="Base Path"
                            error={this.getFieldError("basePath")}
                            validate={required("Enter Base Path.")}
                            disabled={!!this.state.busy}
                        />
                    </ExpandableFormSection>
                </TransitionAnimation>
            </React.Fragment>
        );

        const configurationDialog = this.props.isConfigurationAsCodeEnabled && this.state.model && this.state.project && this.state.commitDialogActive && (
            <CommitDialog
                open={this.state.commitDialogActive}
                defaultSummary={defaultCommitMessage}
                commitMessage={this.state.commitMessage}
                setCommitMessage={(commitMessage: CommitMessageWithDetails) => this.setState({ commitMessage })}
                errors={this.errors}
                project={this.state.project}
                onNext={() => {
                    // TODO: try and clone the repository, passing the commit message.
                    // if that succeeds, save the VCS settings
                    return this.state.saveForm();
                }}
                onClose={() => {
                    this.setState({ commitDialogActive: false });
                }}
            />
        );

        const hasErrors = this.state.vcsCompatibilityReport && this.state.vcsCompatibilityReport.Errors.length > 0 ? true : false;
        const enabled = !!this.state.model && this.state.model.enabled;
        const isVersionControlled = this.state.project && this.state.project.IsVersionControlled ? true : false;
        const isProcessVersionControlled = isVersionControlledProcess(isVersionControlled, ProcessType.Deployment, false);

        return (
            <FormPaperLayout
                title={[<span key="title">Version Control Settings&nbsp;</span>, <GitChip key="chip" size="small" enabled={enabled} />]}
                busy={this.state.busy}
                errors={this.errors}
                model={this.state.model}
                cleanModel={this.state.cleanModel}
                saveButtonLabel={enabled ? "Save" : "Configure..."}
                saveButtonBusyLabel={enabled ? "Saving" : "Configuring..."}
                savePermission={this.editPermission()}
                onSaveClick={this.handleSaveClick}
                forceDisableFormSaveButton={hasErrors}
                forceDisabledReason={"Conversion errors must be resolved"}
                customPrimaryAction={(primaryActionProps: PrimaryActionProps) => {
                    // For this page (unlike most form layouts) we only save after a successful clone.
                    // We do that by first popping up a dialog, doing the clone and then saving if it succeeds.
                    // In order to do that, here we're storing a reference to the default form save function
                    // to call later on from the dialog.
                    const onClick = enabled
                        ? primaryActionProps.onClick
                        : (e: React.MouseEvent) => {
                              e.preventDefault();
                              this.setState({
                                  saveForm: primaryActionProps.onClick as () => Promise<boolean>,
                                  commitDialogActive: true,
                              });
                          };

                    return <ActionButton type={ActionButtonType.Save} label={primaryActionProps.label} disabled={primaryActionProps.disabled} busyLabel={primaryActionProps.busyLabel} onClick={onClick} />;
                }}
                saveText="Your project now supports version control"
                secondaryAction={!hasErrors && this.state.model && this.state.project && <TestConnectionButton disabled={this.state.busy || !this.state.model.url} project={this.state.project} model={this.state.model} />}
            >
                <EarlyAccessWarningSection isConfigurationAsCodeEnabled={this.props.isConfigurationAsCodeEnabled} isVersionControlled={this.state.project ? this.state.project.IsVersionControlled : false} />
                {this.state.vcsCompatibilityReport && <UnsupportedFeaturesSection vcsCompatibilityReport={this.state.vcsCompatibilityReport} />}
                {formFields}
                {configurationDialog}
            </FormPaperLayout>
        );
    }

    private summary(): SummaryNode {
        const url = this.state.model && this.state.model.url;
        return url ? Summary.summary(url) : Summary.placeholder("Enter a Git repository URL");
    }

    private authSummary(): SummaryNode {
        const username = this.state.model && this.state.model.username;
        const password = this.state.model && this.state.model.password;
        if (username && password && password.HasValue) {
            return Summary.summary(
                React.Children.toArray([
                    <span>Authenticated with username</span>,
                    <span>
                        {" "}
                        <strong>{username}</strong>
                    </span>,
                ])
            );
        }

        return Summary.placeholder("Enter authentication details");
    }

    private branchSummary(): SummaryNode {
        if (!this.state.model || this.state.model.defaultBranch === branchNameToShowByDefault) {
            return Summary.default(branchNameToShowByDefault);
        }

        if (!this.state.model.defaultBranch) {
            return Summary.placeholder("Enter a default branch");
        }

        return Summary.summary(this.state.model.defaultBranch);
    }

    private basePathSummary(): SummaryNode {
        // TODO: Refactor
        const basePathToShowByDefault = this.state.project ? getBasePathToShowByDefault(this.state.project.Name) : "";

        if (!this.state.model || this.state.model.defaultBranch === basePathToShowByDefault) {
            return Summary.default(basePathToShowByDefault);
        }

        if (!this.state.model.basePath) {
            return Summary.placeholder("Enter a base path");
        }

        return Summary.summary(this.state.model.basePath);
    }

    private editPermission(): PermissionCheckProps {
        return {
            permission: Permission.ProjectEdit,
            project: this.state.project && this.state.project.Id,
            tenant: "*",
        };
    }

    private async saveProject(project: ProjectResource) {
        const result = await repository.Projects.save(project);

        this.setState({
            model: this.buildModel(result),
            cleanModel: this.buildModel(result),
            project: result,
        });
    }

    private async convertProjectToVcs(project: ProjectResource, commitMessage: string) {
        await repository.Projects.convertToVcs(project, commitMessage);
        const updatedProject = await repository.Projects.get(project.Id);
        await this.props.projectContext.actions.onVersionControlEnabled(updatedProject);

        this.setState({
            model: this.buildModel(updatedProject),
            cleanModel: this.buildModel(updatedProject),
            project: updatedProject,
        });
    }
}

const mapGlobalStateToProps = (state: GlobalState): GlobalConnectedProps => {
    return {
        isConfigurationAsCodeEnabled: configurationSelectors.createFeatureEnabledSelector((t) => t.isConfigurationAsCodeEnabled)(state),
    };
};

const VersionControlSettings = connect(mapGlobalStateToProps, null)(withProjectContext(VersionControlSettingsInternal));

export default VersionControlSettings;

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { TeamResource } from "client/resources";
import MultiSelect from "./MultiSelect";
import { FormFieldProps } from "components/form";
import { ContextualMissingChip, MissingChip, TeamChip } from "components/Chips";
import { ChipIcon } from "components/Chips";
import { SelectItem } from "components/VirtualListWithKeyboard/SelectItem";
import Lookup from "../Lookup";
import { useKeyedItemAccess } from "../KeyAccessProvider/KeyedItemAccessProvider";

interface TeamMultiSelectProps extends FormFieldProps<string[]> {
    items: TeamResource[];
    canBeDeleted?: (team: TeamResource) => boolean;
    label?: string | JSX.Element;
    error?: string;
    descriptionPostfix?: (team: TeamResource) => string;
}

const TeamTypedMultiSelect = MultiSelect<TeamResource>();

const TeamMultiSelect: React.FC<TeamMultiSelectProps> = (props) => {
    const itemKey = useKeyedItemAccess();

    const chipRenderer = (r: TeamResource | SelectItem, onRequestDelete: (event: object) => void) => {
        return (
            <Lookup
                lookupCollection={props.items}
                lookupId={r[itemKey]}
                getIdFromElement={(element) => element[itemKey]}
                render={(item) => {
                    const onRequestDeleteProp = props.canBeDeleted!(item) ? { onRequestDelete } : {};
                    return <TeamChip {...onRequestDeleteProp} deleteButtonAccessibleName={`Delete ${item.Name}`} team={item} descriptionPostfix={props.descriptionPostfix ? props.descriptionPostfix(item) : null!} />;
                }}
                renderFallback={<ContextualMissingChip lookupKey={r[itemKey]} type={ChipIcon.Team} onRequestDelete={onRequestDelete} />}
            />
        );
    };

    return <TeamTypedMultiSelect label="Select teams" renderChip={chipRenderer} itemKey={itemKey} {...props} />;
};

TeamMultiSelect.defaultProps = {
    canBeDeleted: (team) => true,
};

export default TeamMultiSelect;

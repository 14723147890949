import React from "react";
import { client } from "clientInstance";
import BusyFromPromise from "components/BusyFromPromise";
import BusyIndicator from "components/BusyIndicator";
import { DoBusyTask, Errors } from "components/DataBaseComponent";
import { ErrorPanel } from "components/form";
import _ from "lodash";
import useLocalStorage from "hooks/useLocalStorage";
import { resolveStringPathWithSpaceId } from "components/Navigation/resolvePathWithSpaceId";
import Menu from "components/Menu/Menu";
import { useRef } from "react";
// eslint-disable-next-line no-restricted-imports
import { MenuItem } from "material-ui";
import { EmptyGlobalSearchResults, getRedirectUrl, GlobalSearchResult, IsPageSearchResult, NoKeywordGuidance, PageSearchResultListItem, SearchItemCount, SpaceSearchResultListItem, usePageSearch, useSpaceSearch } from "./GlobalSearchHelpers";
import { connect } from "react-redux";
import configurationSelectors from "areas/configuration/reducers/selectors";

const styles = require("./GlobalSearch.less");

interface GlobalSearchListProps {
    items: GlobalSearchResult[];
    keyword: string;
    selectedIndex: number;
    resetToSelectedIndex: (index: number) => void;
    onClick: (item: GlobalSearchResult) => void;
    busy?: Promise<unknown> | boolean;
}

const GlobalSearchList: React.FC<GlobalSearchListProps> = ({ items, keyword, selectedIndex, resetToSelectedIndex, onClick, busy }): React.ReactElement | null => {
    const menuRef = useRef<Menu | null>(null);

    React.useEffect(() => {
        if (selectedIndex > -1 && selectedIndex < items.length) {
            menuRef?.current?.setFocusIndex(undefined, selectedIndex, true);
        }
        if (selectedIndex >= items.length) {
            resetToSelectedIndex(0); // Go back to first result.
        }
    }, [selectedIndex, resetToSelectedIndex, menuRef, items.length]);

    const commonStyle: React.CSSProperties = { whiteSpace: "normal", lineHeight: "1.4", padding: "0.4rem 1rem 0.4rem 0", minHeight: "0" };
    const commonInnerDivStyle: React.CSSProperties = { padding: "0.5rem 1rem" };
    const selectedValue = items.length >= selectedIndex ? items[selectedIndex] : null;
    const maxHeightMinusResultsSummary = 550;
    const maxWidth = 440; // This matches our container CSS.
    return (
        <Menu
            ref={menuRef}
            disableAutoFocus={true}
            maxHeight={maxHeightMinusResultsSummary}
            autoWidth={false}
            width={maxWidth}
            desktop={true}
            value={selectedValue}
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
            onItemTouchTap={(event: any, _: any, index: number) => {
                event?.preventDefault();

                // This method helps us enable keyboard support on the menu.
                // If you make changes here, please test carefully.
                const item = items.length >= index ? items[index] : null;
                if (item) {
                    const redirectTo = getRedirectUrl(item);
                    const goTo = `#${resolveStringPathWithSpaceId(redirectTo, client.spaceId ?? "")}`;
                    window.location.href = goTo;
                    onClick(item);
                }
            }}
        >
            {items.length === 0 && <EmptyGlobalSearchResults busy={busy} keyword={keyword} />}
            {items.map((item, _) => {
                if (IsPageSearchResult(item)) {
                    return <MenuItem style={commonStyle} innerDivStyle={commonInnerDivStyle} key={item.page.Id} value={item.page.Name} primaryText={<PageSearchResultListItem result={item} />} />;
                }
                return <MenuItem style={commonStyle} innerDivStyle={commonInnerDivStyle} key={item.Id} value={item.Name} primaryText={<SpaceSearchResultListItem result={item} keyword={keyword} />} />;
            })}
        </Menu>
    );
};

interface GlobalSearchProps {
    keyword: string;
    selectedIndex: number;
    resetToSelectedIndex: (index: number) => void;
    doBusyTask: DoBusyTask;
    busy?: Promise<void>;
    errors?: Errors;
    handleSelect?: () => void;
    handleClose: () => void;
}

interface GlobalConnectedProps {
    isImportExportEnabled?: boolean;
}

type Props = GlobalSearchProps & GlobalConnectedProps;

const GlobalSearchInternal: React.FC<Props> = ({ keyword, selectedIndex, resetToSelectedIndex, doBusyTask, busy, errors, handleSelect, handleClose, isImportExportEnabled }) => {
    const pageSearchResults = usePageSearch(keyword, isImportExportEnabled ?? false);
    const spaceSearchResults = useSpaceSearch(keyword, doBusyTask);
    const [showServerResultsOnly, setShowServerResultsOnly] = useLocalStorage("GlobalSearch.showServerResultsOnly", false);

    const allResults = React.useMemo(() => {
        if (showServerResultsOnly) {
            return [...spaceSearchResults];
        }

        // We put page search results first because they are in-memory searches and won't cause
        // jumping-of-the-UI (like the spaceSearchResults will if they go first).
        return [...pageSearchResults, ...spaceSearchResults];
    }, [pageSearchResults, spaceSearchResults, showServerResultsOnly]);

    return (
        <div className={styles.root}>
            {errors && <ErrorPanel message={errors.message} errors={errors.errors} scrollToPanel={false} />}
            <BusyFromPromise promise={busy}>{(isBusy: boolean) => <BusyIndicator show={isBusy} inline={false} />}</BusyFromPromise>
            {keyword ? <SearchItemCount items={allResults} showServerResultsOnly={showServerResultsOnly} setShowServerResultsOnly={setShowServerResultsOnly} /> : <NoKeywordGuidance />}
            <div className={styles.tabContainer}>
                <GlobalSearchList
                    keyword={keyword}
                    selectedIndex={selectedIndex}
                    resetToSelectedIndex={resetToSelectedIndex}
                    items={allResults}
                    onClick={() => {
                        if (handleSelect) {
                            handleSelect();
                        }
                        handleClose();
                    }}
                    busy={busy}
                />
            </div>
        </div>
    );
};

const mapGlobalStateToProps = (state: GlobalState): GlobalConnectedProps => {
    return {
        isImportExportEnabled: configurationSelectors.createFeatureEnabledSelector((t) => t.isImportExportEnabled)(state),
    };
};

const GlobalSearch = connect(mapGlobalStateToProps, null)(GlobalSearchInternal);

export default GlobalSearch;

import React from "react";
import { LargeWidthSnackbar } from "./internal";
import { useWebpackHotModuleProgress } from "./useWebpackHotModuleProgress";

export const BuildProgressSnackbar: React.FC = () => {
    const progress = useWebpackHotModuleProgress();
    const text = "[WDS] ".concat(progress.percent.toString(), "% - ").concat(progress.message, ".");
    return <LargeWidthSnackbar open={progress.percent > 0 && progress.percent < 100} message={text} />;
};

export default BuildProgressSnackbar;

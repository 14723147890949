import * as React from "react";
import { ClientConnector } from "areas/infrastructure/components/ClientConnector/ClientConnector";
import DynamicExtensionsLoader from "areas/dynamicExtensions/dynamicExtensionsLoader";
import { DevToolsContextProvider } from "components/DevTools/DevToolsContext";
import { OctopusRouter } from "components/OctopusRouter/OctopusRouter";
import { ErrorContextProvider } from "components/ErrorContext/ErrorContext";
import { Provider } from "react-redux";
import store from "store";
import { ThemeApplier } from "components/Theme/ThemeApplier";
import Theme from "components/Theme";
import { FocusContextProvider } from "components/FocusContext";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

const App: React.FC = () => {
    return (
        <ErrorBoundary>
            <ErrorContextProvider>
                <Provider store={store}>
                    <ThemeApplier />
                    <Theme>
                        <FocusContextProvider value={{ current: null }}>
                            <ClientConnector
                                renderWhenConnected={() => (
                                    <DevToolsContextProvider>
                                        <DynamicExtensionsLoader />
                                        <OctopusRouter />
                                    </DevToolsContextProvider>
                                )}
                            />
                        </FocusContextProvider>
                    </Theme>
                </Provider>
            </ErrorContextProvider>
        </ErrorBoundary>
    );
};

export default App;

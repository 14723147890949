/* eslint-disable @typescript-eslint/consistent-type-assertions */
import * as React from "react";
import { FormFieldProps } from "components/form";
import { MultiSelect } from "components/MultiSelect";
import { EndpointCommunicationStyleChip, MissingChip } from "components/Chips";
import { ChipIcon } from "components/Chips";
import { CommunicationStyle, CommunicationStyleResource } from "client/resources";
import { SelectItem } from "components/VirtualListWithKeyboard/SelectItem";
import Lookup from "../Lookup";

interface EndpointCommunicationStyleMultiSelectProps extends FormFieldProps<CommunicationStyle[]> {
    items: CommunicationStyleResource[];
    label?: string | JSX.Element;
    error?: string;
}

const EndpointCommunicationStyleTypedMultiSelect = MultiSelect<CommunicationStyleResource>();

const EndpointCommunicationStyleMultiSelect: React.FC<EndpointCommunicationStyleMultiSelectProps> = (props) => {
    const { onChange, ...otherProps } = props;
    const chipRenderer = (r: CommunicationStyleResource | SelectItem, onRequestDelete: (event: object) => void) => {
        return (
            <Lookup
                lookupCollection={props.items}
                lookupId={r.Id}
                getIdFromElement={(element: CommunicationStyleResource) => element.Id}
                render={(item: CommunicationStyleResource) => {
                    return <EndpointCommunicationStyleChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} commStyle={item} />;
                }}
                renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.CommunicationStyle} />}
            />
        );
    };

    return <EndpointCommunicationStyleTypedMultiSelect fieldName="communication styles" renderChip={chipRenderer} onChange={(value) => onChange(value as CommunicationStyle[])} {...otherProps} />;
};

export default EndpointCommunicationStyleMultiSelect;

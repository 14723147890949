import * as React from "react";
import MultiSelect from "./MultiSelect";
import { FormFieldProps } from "components/form";
import { MissingChip, StepChip } from "components/Chips";
import ReferenceDataItem from "client/resources/referenceDataItem";
import { ChipIcon } from "components/Chips";
import Lookup from "../Lookup";

interface StepReferenceDataItem extends ReferenceDataItem {
    suffix?: React.ReactNode;
}

interface StepMultiSelectProps extends FormFieldProps<string[]> {
    items: StepReferenceDataItem[];
    customLabel?: string;
    openOnFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    hideFloatingLabel?: boolean;
}

const StepTypedMultiSelect = MultiSelect<StepReferenceDataItem>();

const StepMultiSelect: React.FC<StepMultiSelectProps> = (props) => {
    const chipRenderer = (r: StepReferenceDataItem, onRequestDelete: (event: object) => void) => {
        return (
            <Lookup
                lookupCollection={props.items}
                lookupId={r.Id}
                getIdFromElement={(element) => element.Id}
                render={(item) => <StepChip onRequestDelete={onRequestDelete} deleteButtonAccessibleName={`Delete ${item.Name}`} stepName={item.Name} suffix={item.suffix} />}
                renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.Step} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete} />}
            />
        );
    };

    return <StepTypedMultiSelect fieldName="deployment steps" renderChip={chipRenderer} {...props} />;
};

export default StepMultiSelect;

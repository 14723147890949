import * as React from "react";
import { Switch, RouteComponentProps, withRouter } from "react-router-dom";
import { ChannelsRoute } from "../Channels";
import DeploymentProcessRoute from "../Process/Routing/ProcessRoute";
import ReloadableRoute from "components/ReloadableRoute/ReloadableRoute";
import { DeploymentsOverview } from "../DeploymentsOverview";
import { ReleasesRoute } from "../Releases";
import { deploymentLinks } from "./deploymentRouteLinks";
import { ProcessType } from "client/resources";
import DeploymentProcessSettings from "areas/projects/components/DeploymentProcessSettings/DeploymentProcessSettings";
import { withPage } from "components/Page/Page";
import pageIds from "pageIds";

type DeploymentsRouteProps = { path: string } & RouteComponentProps;
const DeploymentProcessSettingsPage = withPage({ page: pageIds.project().deploymentSettings })(DeploymentProcessSettings);

export class DeploymentsRoute extends React.Component<DeploymentsRouteProps> {
    render() {
        const links = deploymentLinks(this.props.path);
        const match = this.props.match;

        return (
            <ReloadableRoute path={links.root}>
                <Switch>
                    <DeploymentProcessRoute path={`${match.path}/process`} processType={ProcessType.Deployment} />
                    <ReloadableRoute path={`${match.path}/channels`} component={ChannelsRoute} />
                    <ReloadableRoute path={`${match.path}/releases`} component={ReleasesRoute} />
                    <ReloadableRoute path={`${match.path}/settings`} component={DeploymentProcessSettingsPage} />
                    <ReloadableRoute path={`${match.path}`} component={DeploymentsOverview} />
                </Switch>
            </ReloadableRoute>
        );
    }
}

const EnhancedDeploymentsRoute = withRouter(DeploymentsRoute);

export default EnhancedDeploymentsRoute;

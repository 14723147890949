import * as React from "react";
import Tasks from "areas/tasks/components/Tasks/Tasks";
import AreaTitle from "components/AreaTitle";
import { repository, session } from "clientInstance";
import { NavigationButton } from "components/Button";
import { SpaceResource } from "client/resources";
import routeLinks from "routeLinks";
import DataBaseComponent, { DataBaseComponentState } from "components/DataBaseComponent";
import Callout, { CalloutType } from "primitiveComponents/dataDisplay/Callout";
import DrawerWrapperLayout from "components/Drawer/DrawerWrapperLayout";
import PaperLayout from "components/PaperLayout";
import ActionList from "components/ActionList";
const styles = require("./style.less");

const ImportExportTasksFilter: React.FC = () => {
    return (
        <div className={styles.container}>
            <Tasks restrictToTaskTypes={["ExportProjects", "ImportProjects"]} hideScriptConsoleAction={true} hideAdvancedFilters={true} />
        </div>
    );
};

interface ImportExportTasksState extends DataBaseComponentState {
    space?: SpaceResource;
    importExportIsEnabled: boolean;
}

class ImportExportTasks extends DataBaseComponent<{}, ImportExportTasksState> {
    constructor(props: {}) {
        super(props);

        this.state = {
            importExportIsEnabled: false,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            if (!repository.spaceId) {
                throw new Error("Attempted to render ImportExportTasks in a system context. This should never happen.");
            }
            const [space, featuresConfiguration] = await Promise.all([repository.Spaces.get(repository.spaceId), repository.FeaturesConfiguration.get()]);

            this.setState({
                space,
                importExportIsEnabled: featuresConfiguration.IsImportExportEnabled,
            });
        });
    }

    isSpaceManager(): boolean {
        if (!session.currentPermissions) {
            throw new Error("Attempted to access the current user's permissions, but they weren't found. This should never happen.");
        }
        return this.state.space !== undefined && session.currentPermissions.isSpaceManager(this.state.space);
    }

    render() {
        const isSpaceManager = this.isSpaceManager();
        return (
            <main id="maincontent">
                <AreaTitle link={routeLinks.importExport.root} title="Import/Export" breadcrumbTitle="Projects" breadcrumbPath={routeLinks.projects.root}>
                    {this.state.space && this.isSpaceManager() && this.state.importExportIsEnabled && (
                        <ActionList actions={[<NavigationButton label="Import" href={routeLinks.import.root} />, <NavigationButton label="Export" href={routeLinks.export.root} />]} />
                    )}
                </AreaTitle>
                <DrawerWrapperLayout>
                    <PaperLayout title={null} busy={this.state.busy} errors={this.errors} fullWidth={true} flatStyle={true}>
                        {this.state.space && !isSpaceManager && (
                            <Callout title="Permissions" type={CalloutType.Danger}>
                                Only Space Managers can perform an export or import.
                            </Callout>
                        )}
                        {this.state.space && isSpaceManager && !this.state.importExportIsEnabled && (
                            <Callout title="Feature Toggle" type={CalloutType.Information}>
                                The Import/Export feature is not currently enabled.
                            </Callout>
                        )}
                        {this.state.space && isSpaceManager && this.state.importExportIsEnabled && (
                            <>
                                <Callout title="Early Access Feature" type={CalloutType.Warning}>
                                    TBD
                                </Callout>
                                <ImportExportTasksFilter />
                            </>
                        )}
                    </PaperLayout>
                </DrawerWrapperLayout>
            </main>
        );
    }
}

export default ImportExportTasks;

/* eslint-disable @typescript-eslint/consistent-type-assertions */
import * as React from "react";
import { ProcessReferenceDataItem } from "client/resources";
import { MultiSelect } from "components/MultiSelect";
import { FormFieldProps } from "components/form";
import { MissingChip, ProcessChip } from "components/Chips";
import { Item } from "components/VirtualListWithKeyboard/VirtualListWithKeyboard";
import { FocusableComponent } from "components/VirtualListWithKeyboard/FocusableComponent";
import type { ComponentProps } from "utils/types/MappedTypes";
import Lookup from "../Lookup";

const ProcessTypedMultiSelect = MultiSelect<ProcessReferenceDataItem>();
type RenderChip = ComponentProps<typeof ProcessTypedMultiSelect>["renderChip"];

interface ProcessMultiSelectProps extends FormFieldProps<string[]> {
    items: ProcessReferenceDataItem[];
    openOnFocus?: boolean;
    label?: string | JSX.Element;
    error?: string;
    hideFloatingLabel?: boolean;
    multiSelectRef?(component: FocusableComponent | null): void;
}

const ProcessMultiSelect: React.FC<ProcessMultiSelectProps> = (props) => {
    const chipRenderer: RenderChip = React.useCallback(
        (r, onRequestDelete: (event: object) => void) => {
            return (
                <Lookup
                    lookupCollection={props.items}
                    lookupId={r.Id}
                    getIdFromElement={(element) => element.Id}
                    render={(item) => <ProcessChip processType={item.ProcessType} onRequestDelete={onRequestDelete} deleteButtonAccessibleName={`Delete ${r.Name}`} name={r.Name} />}
                    renderFallback={<MissingChip lookupId={r.Id} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete} />}
                />
            );
        },
        [props.items]
    );

    const itemRender = React.useCallback((r: ProcessReferenceDataItem): Item => ({ primaryText: r.Name, secondaryText: r.ProcessType }), []);

    return <ProcessTypedMultiSelect renderItem={itemRender} fieldName="processes" renderChip={chipRenderer} {...props} />;
};

export default ProcessMultiSelect;

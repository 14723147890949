import * as React from "react";
import { EmptyPage, ImageHeight } from "../EmptyPage/EmptyPage";
import { OnboardingCowboy } from "../Images/GettingStarted/OnboardingCowboy";

interface OnboardingPageProps {
    title: string;
    intro?: React.ReactNode | string;
    panels?: Array<{
        description: React.ReactNode;
        imageSrc: string;
        imageWidth: number;
        imageHeight: number; // Needed to ensure image dimensions are set on page load (to stop the content jumping around as images load).
        imageAlt: string;
    }>;
    learnMore: React.ReactNode;
}

class OnboardingPage extends React.Component<OnboardingPageProps, never> {
    render() {
        return <EmptyPage title={this.props.title} description={this.props.intro} image={<OnboardingCowboy height={ImageHeight} margin={"2rem 0"} />} panels={this.props.panels} learnMore={this.props.learnMore} />;
    }
}

export default OnboardingPage;

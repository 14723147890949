import * as React from "react";
import { History } from "history";
type LocationDescriptor = History.LocationDescriptor;
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import { OverflowMenuNavLink } from "components/Menu/OverflowMenu";
import DropdownButton, { DropdownButtonMenu, DropdownButtonMenuItem } from "components/Button/DropdownButton";
import { ActionButtonType } from "components/Button";

const styles = require("./style.less");

interface BreadcrumbProps {
    title?: string; // If specified, this text will display above the title.
    path?: LocationDescriptor; // If specified, this will link your breadcrumb title.
    overflow?: OverflowMenuNavLink[]; // If specified, this will create a Dropdown button with ternary theme and the specified overflow items
    isAreaLevelBreadcrumb?: boolean;
}

const Breadcrumb: React.StatelessComponent<BreadcrumbProps> = (props) => {
    return props.title ? (
        <div className={styles.breadcrumb}>
            <BreadcrumbContent {...props} />
        </div>
    ) : null;
};

const BreadcrumbContent = (props: BreadcrumbProps) => {
    const [open, setOpen] = React.useState(false);

    if (props.overflow && props.title) {
        return (
            <DropdownButton open={open} onClick={() => setOpen(true)} onClose={() => setOpen(false)} type={ActionButtonType.Ternary} label={props.title}>
                {(renderProps) => (
                    <DropdownButtonMenu {...renderProps.getMenuProps()}>
                        {props.overflow?.map((menuItem) => (
                            <DropdownButtonMenuItem key={menuItem.text} {...renderProps.getMenuItemProps({ onClick: () => renderProps.navigation.navigate(menuItem.path) })}>
                                {menuItem.text}
                            </DropdownButtonMenuItem>
                        ))}
                    </DropdownButtonMenu>
                )}
            </DropdownButton>
        );
    } else if (props.path) {
        return (
            <InternalLink className={props.isAreaLevelBreadcrumb ? styles.breadcrumbLink : null} to={props.path}>
                {props.title}
            </InternalLink>
        );
    }

    return <div>{props.title}</div>;
};

export default Breadcrumb;

import * as React from "react";
import { NamedResource, DocumentTypeResource } from "client/resources";
import { MultiSelect } from "components/MultiSelect";
import { FormFieldProps } from "components/form";
import { DocumentTypeChip, MissingChip } from "components/Chips";
import { ChipIcon } from "components/Chips";
import { SelectItem } from "components/VirtualListWithKeyboard/SelectItem";
import Lookup from "../Lookup";

interface DocumentTypeMultiSelectProps extends FormFieldProps<string[]> {
    items: DocumentTypeResource[];
    label?: string | JSX.Element;
    error?: string;
}

const DocumentTypeTypedMultiSelect = MultiSelect<DocumentTypeResource>();

const DocumentTypeMultiSelect: React.FC<DocumentTypeMultiSelectProps> = (props) => {
    const chipRenderer = (r: DocumentTypeResource | SelectItem, onRequestDelete: (event: object) => void) => {
        return (
            <Lookup
                lookupCollection={props.items}
                lookupId={r.Id}
                getIdFromElement={(element: NamedResource) => element.Id}
                render={(item) => <DocumentTypeChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} documentType={item} />}
                renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.DocumentType} />}
            />
        );
    };

    return <DocumentTypeTypedMultiSelect fieldName="document types" renderChip={chipRenderer} {...props} />;
};

export default DocumentTypeMultiSelect;

/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import { RouteComponentProps, useRouteMatch, useHistory, useLocation } from "react-router";
import DataBaseComponent from "components/DataBaseComponent";
import Task from "areas/tasks/components/Task/Task";
import DrawerWrapperLayout from "components/Drawer/DrawerWrapperLayout";
import AreaTitle from "components/AreaTitle";
import routeLinks from "routeLinks";
import { SpaceResource } from "client/resources";
import Callout, { CalloutType } from "primitiveComponents/dataDisplay/Callout";
import { repository, session } from "clientInstance";
const styles = require("./style.less");

class ExportTaskDetailsInternal extends DataBaseComponent<RouteComponentProps<{ taskId: string }>, { space?: SpaceResource }> {
    taskId: string;

    constructor(props: RouteComponentProps<{ taskId: string }>) {
        super(props);
        this.taskId = this.props.match.params.taskId;
        this.state = {};
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const [space] = await Promise.all([repository.Spaces.get(repository.spaceId!)]);

            this.setState({
                space,
            });
        });
    }

    isSpaceManager(): boolean {
        return this.state.space !== undefined && session.currentPermissions!.isSpaceManager(this.state.space);
    }

    render() {
        return (
            <main id="maincontent">
                <DrawerWrapperLayout>
                    <AreaTitle link={routeLinks.importExport.root} title="Import/Export" breadcrumbTitle="Projects" breadcrumbPath={routeLinks.projects.root} />
                    {this.state.space !== undefined && !this.isSpaceManager() && (
                        <Callout title="Permissions" type={CalloutType.Danger}>
                            Only Space Managers can perform an export or import.
                        </Callout>
                    )}
                    {this.state.space !== undefined && this.isSpaceManager() && (
                        <div className={styles.container}>
                            <Task taskId={this.taskId} delayRender={() => false} />
                        </div>
                    )}
                </DrawerWrapperLayout>
            </main>
        );
    }
}

const ExportTaskDetails: React.FC = () => {
    const match = useRouteMatch<{ taskId: string }>();
    return <ExportTaskDetailsInternal location={useLocation()} history={useHistory()} match={match!} />;
};
export { ExportTaskDetails };

/* eslint-disable no-restricted-imports */

import React from "react";
import Snackbar, { SnackbarProps } from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    root: {
        width: "60%",
        display: "block",
        margin: "1.5em",
        bottom: 0,
    },
});

export const LargeWidthSnackbar: React.FC<SnackbarProps> = (props) => {
    const classes = useStyles();
    return <Snackbar classes={classes} {...props} />;
};

export default LargeWidthSnackbar;

import * as React from "react";
import AreaTitle from "components/AreaTitle";
import routeLinks from "routeLinks";
import { ExpandableFormSection, RadioButton, RadioButtonGroup, Select, Sensitive, Summary, SummaryNode, FormSection } from "components/form";
import DataBaseComponent, { DataBaseComponentState } from "components/DataBaseComponent";
import { TaskResource, SensitiveValue, SpaceResource } from "client/resources";
import ProjectImportFile from "client/resources/projectImportFile";
import { repository, session } from "clientInstance";
import PaperLayout from "components/PaperLayout";
import ActionButton, { ActionButtonType } from "components/Button";
import InternalRedirect from "components/Navigation/InternalRedirect";
import Callout, { CalloutType } from "primitiveComponents/dataDisplay/Callout";
import DrawerWrapperLayout from "components/Drawer/DrawerWrapperLayout";
import ExpansionButtons from "components/form/Sections/ExpansionButtons";
import InternalLink from "components/Navigation/InternalLink/InternalLink";
import FormSectionHeading from "components/form/Sections/FormSectionHeading";
import UnstructuredFormSection from "components/form/Sections/UnstructuredFormSection";
import ProjectImportPreviewResponse from "client/resources/projectImportPreviewResponse";
import ProjectImportSource from "client/resources/projectImportSource";
import ActionList from "components/ActionList";
import FileUploadDropzone from "primitiveComponents/form/FileUploadDragDrop/FileUploadDragDrop";
const styles = require("./style.less");

interface ImportProjectsState extends DataBaseComponentState {
    importExportIsEnabled: boolean;
    space?: SpaceResource;
    spaces?: SpaceResource[];
    password: SensitiveValue;
    importSourceType: string;
    importFromTaskInSpaceId?: string;
    tasks: TaskResource[];
    importFromTask?: TaskResource;
    importFromTaskId?: string;
    redirectPath?: string;
    importFromFileId?: string;
    previewResponse?: ProjectImportPreviewResponse;
    file?: File;
    hasFileBeenUploaded: boolean;
    hasFileUploadStarted: boolean;
}

class ImportProjectsInternal extends DataBaseComponent<{}, ImportProjectsState> {
    constructor(props: {}) {
        super(props);

        this.state = {
            importExportIsEnabled: false,
            importSourceType: "space",
            password: { HasValue: false },
            tasks: [],
            hasFileBeenUploaded: false,
            hasFileUploadStarted: false,
        };
    }

    async componentDidMount() {
        await this.doBusyTask(async () => {
            const [spaces, featuresConfiguration] = await Promise.all([repository.Spaces.all(), repository.FeaturesConfiguration.get()]);
            const otherSpaces = spaces.filter((s) => s.Id != repository.spaceId);

            this.setState({
                space: spaces.filter((s) => s.Id === repository.spaceId)[0],
                spaces: otherSpaces,
                importSourceType: otherSpaces.length === 0 ? "upload" : "space",
                importExportIsEnabled: featuresConfiguration.IsImportExportEnabled,
            });
        });
    }

    isSpaceManager(): boolean {
        if (!session.currentPermissions) {
            throw new Error("Attempted to access the current user's permissions, but they weren't found. This should never happen.");
        }
        return this.state.space !== undefined && session.currentPermissions.isSpaceManager(this.state.space);
    }

    getPreview = async () => {
        this.setState({
            previewResponse: undefined,
        });
        await this.doBusyTask(async () => {
            const request = { Password: this.state.password, ImportSource: this.buildImportSource() };
            const projectImportResponse = await repository.ImportExport.preview(request);

            this.setState({
                previewResponse: projectImportResponse,
            });
        });
    };

    buildImportSource = (): ProjectImportSource => {
        if (this.state.importSourceType === "space" && this.state.importFromTaskInSpaceId && this.state.importFromTask) {
            return { Type: "space", SpaceId: this.state.importFromTaskInSpaceId, TaskId: this.state.importFromTask.Id };
        } else if (this.state.importFromFileId) {
            return { Type: "upload", UploadedFileId: this.state.importFromFileId };
        }
        throw new Error("Unknown import source type");
    };

    doImport = async () => {
        await this.doBusyTask(async () => {
            const request = { Password: this.state.password, ImportSource: this.buildImportSource() };
            const projectImportResource = await repository.ImportExport.import(request);

            this.setState({
                redirectPath: routeLinks.task(projectImportResource.TaskId).root,
            });
        });
    };

    afterUpload = async (file: ProjectImportFile) => {
        this.setState({
            importFromFileId: file.Id,
            hasFileBeenUploaded: true,
        });
    };

    spaceChanged = async (spaceId?: string) => {
        if (!spaceId) {
            this.setState({
                tasks: [],
                importFromTaskInSpaceId: undefined,
                importFromTask: undefined,
                previewResponse: undefined,
            });
            return;
        }
        console.log("Select changed to space " + spaceId);
        await this.doBusyTask(async () => {
            const tasks = await this.getTasks(spaceId);
            this.setState({
                importFromTaskInSpaceId: spaceId,
                importFromTask: undefined,
                tasks,
                previewResponse: undefined,
            });
        });
    };

    getTasks = async (spaceId?: string): Promise<TaskResource[]> => {
        if (!spaceId || !repository.spaceId) {
            return [];
        }

        const itemsToTake = 100;
        const currentSpaceId = repository.spaceId;
        console.log("Switching to space " + spaceId);
        await repository.switchToSpace(spaceId);

        const taskFilter = { name: "ExportProjects", itemsToTake, states: "Success" };

        const tasks = await repository.Tasks.list(taskFilter);

        console.log("Switching back to space " + currentSpaceId);
        await repository.switchToSpace(currentSpaceId);

        return tasks.Items;
    };

    uploadFile = async () => {
        this.setState({ hasFileUploadStarted: true });
        return this.doBusyTask(async () => {
            if (!this.state.file) {
                throw new Error("No file selected, this shouldn't happen");
            }
            const uploadedFile = await repository.ImportExport.upload(this.state.file);
            await this.afterUpload(uploadedFile);
        });
    };

    sanitiseTaskDescription = (description: string): string => {
        return description.replace("Project export: ", "");
    };

    render() {
        if (this.state.redirectPath) {
            return <InternalRedirect to={this.state.redirectPath} push={true} />;
        }

        const previewAction = (
            <ActionButton type={ActionButtonType.Secondary} label="Preview" onClick={() => this.getPreview()} disabled={this.state.busy || !this.isRequiredDataComplete() || (this.state.hasFileUploadStarted && !this.state.hasFileBeenUploaded)} />
        );
        const importAction = (
            <ActionButton type={ActionButtonType.Primary} label="Import" onClick={() => this.doImport()} disabled={this.state.busy || !this.state.previewResponse || (this.state.hasFileUploadStarted && !this.state.hasFileBeenUploaded)} />
        );

        return (
            <main id="maincontent">
                <AreaTitle link={routeLinks.importExport.root} title="Import/Export" breadcrumbTitle="Projects" breadcrumbPath={routeLinks.projects.root} />
                <DrawerWrapperLayout>
                    <div className={styles.paperContainer}>
                        <PaperLayout title="Import Projects" busy={this.state.busy} errors={this.errors} sectionControl={<ActionList actions={[previewAction, importAction]} />}>
                            {this.state.space && !this.isSpaceManager() && (
                                <Callout title="Permissions" type={CalloutType.Danger}>
                                    Only Space Managers can perform an export or import.
                                </Callout>
                            )}
                            {this.state.space && this.isSpaceManager() && !this.state.importExportIsEnabled && (
                                <Callout title="Feature Toggle" type={CalloutType.Information}>
                                    The Import/Export feature is not currently enabled.
                                </Callout>
                            )}
                            {this.state.importSourceType === "upload" && this.state.file && this.state.hasFileUploadStarted && !this.state.hasFileBeenUploaded && (
                                <Callout type={CalloutType.Information} title="Import File">
                                    File is being uploaded...
                                </Callout>
                            )}
                            {this.state.importSourceType === "upload" && this.state.file && this.state.hasFileBeenUploaded && (
                                <Callout type={CalloutType.Success} title="Import File">
                                    File has been uploaded.
                                </Callout>
                            )}
                            {this.state.space && this.isSpaceManager() && this.state.importExportIsEnabled && (
                                <>
                                    <ExpansionButtons errors={this.errors} />
                                    <ExpandableFormSection errorKey="importSource" title="Import Source" summary={this.importSourceSummary()} isExpandedByDefault={true} help="Select the file source to import.">
                                        <RadioButtonGroup onChange={(importSource) => this.setState({ importSourceType: importSource })} value={this.state.importSourceType}>
                                            <RadioButton value={"space"} label="Use the zip file exported from another space" isDefault={this.state.importSourceType === "space" && this.state.spaces && this.state.spaces.length > 0} />
                                            {this.state.importSourceType === "space" && this.state.spaces && this.state.spaces.length === 0 && (
                                                <div className={styles.spaceCallout}>
                                                    <Callout type={CalloutType.Warning} title="More than one space required">
                                                        Please <InternalLink to={routeLinks.configuration.spaces.root}>add another Space</InternalLink> and try again.
                                                    </Callout>
                                                </div>
                                            )}
                                            {this.state.importSourceType === "space" && this.state.spaces && this.state.spaces.length > 0 && (
                                                <Select
                                                    label="Select space"
                                                    value={this.state.importFromTaskInSpaceId}
                                                    items={this.state.spaces.map((f) => ({ value: f.Id, text: f.Name }))}
                                                    onChange={(importFromTaskInSpaceId) => this.spaceChanged(importFromTaskInSpaceId)}
                                                />
                                            )}
                                            {this.state.importSourceType === "space" && this.state.spaces && this.state.spaces.length > 0 && (
                                                <Select
                                                    label="Select export"
                                                    value={this.state.importFromTaskId}
                                                    items={this.state.tasks.map((t) => ({ value: t.Id, text: this.sanitiseTaskDescription(t.Description) }))}
                                                    onChange={(importFromTaskId) => this.setState({ importFromTaskId, importFromTask: this.state.tasks.find((t) => t.Id === importFromTaskId), previewResponse: undefined })}
                                                    disabled={!this.state.importFromTaskInSpaceId}
                                                />
                                            )}
                                            <RadioButton value={"upload"} label="Upload a zip file" isDefault={this.state.importSourceType === "space" && this.state.spaces && this.state.spaces.length === 0} />
                                            {this.state.importSourceType === "upload" && !this.state.importFromFileId && !this.state.hasFileUploadStarted && (
                                                <>
                                                    <FileUploadDropzone label={"Drag and drop a file containing exported data"} onFilesChanged={(files) => this.setState({ file: files[0] })} />
                                                    <ActionButton type={ActionButtonType.Primary} label="Upload" onClick={() => this.uploadFile()} disabled={!this.state.file} />
                                                </>
                                            )}
                                        </RadioButtonGroup>
                                    </ExpandableFormSection>
                                    <ExpandableFormSection errorKey="password" title="Password" summary={this.passwordSummary()} isExpandedByDefault={true} help="Enter the password used to protect sensitive values in the imported data.">
                                        <Sensitive label="Password" value={this.state.password} onChange={(password) => this.setState({ password, previewResponse: undefined })} />
                                    </ExpandableFormSection>
                                    <FormSectionHeading title="Preview" />
                                    <FormSection>
                                        {this.state.previewResponse && (
                                            <UnstructuredFormSection>
                                                <ul className={styles.unorderedList}>
                                                    {this.state.previewResponse.Environments.length > 0 && (
                                                        <li>
                                                            <div>
                                                                <i>Environments</i>
                                                            </div>
                                                            <ul className={styles.unorderedList}>
                                                                {this.state.previewResponse.Environments.map((l) => (
                                                                    <li>{l}</li>
                                                                ))}
                                                            </ul>
                                                        </li>
                                                    )}
                                                    {this.state.previewResponse.Lifecycles.length > 0 && (
                                                        <li>
                                                            <div>
                                                                <i>Lifecycles</i>
                                                            </div>
                                                            <ul className={styles.unorderedList}>
                                                                {this.state.previewResponse.Lifecycles.map((l) => (
                                                                    <li>{l}</li>
                                                                ))}
                                                            </ul>
                                                        </li>
                                                    )}
                                                    {this.state.previewResponse.Projects.length > 0 && (
                                                        <li>
                                                            <div>
                                                                <i>Projects</i>
                                                            </div>
                                                            <ul className={styles.unorderedList}>
                                                                {this.state.previewResponse.Projects.map((l) => (
                                                                    <li>
                                                                        <div>{l.Name}</div>
                                                                        <div>
                                                                            <i>Channels</i>
                                                                        </div>
                                                                        <ul className={styles.unorderedList}>
                                                                            {l.Channels.map((c) => (
                                                                                <li>{c}</li>
                                                                            ))}
                                                                        </ul>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </li>
                                                    )}
                                                </ul>
                                            </UnstructuredFormSection>
                                        )}
                                    </FormSection>
                                </>
                            )}
                        </PaperLayout>
                    </div>
                </DrawerWrapperLayout>
            </main>
        );
    }

    isRequiredDataComplete(): boolean {
        return this.isRequiredPasswordDataComplete() && (this.isRequiredSpaceDataComplete() || this.isRequiredUploadDataComplete());
    }

    isRequiredPasswordDataComplete(): boolean {
        return this.state.password.NewValue !== undefined && this.state.password.NewValue !== "";
    }

    isRequiredSpaceDataComplete(): boolean {
        return this.state.importSourceType === "space" && this.state.importFromTaskInSpaceId !== undefined && this.state.importFromTaskId !== "";
    }

    isRequiredUploadDataComplete(): boolean {
        return this.state.importSourceType === "upload" && this.state.importFromFileId !== undefined;
    }

    importSourceSummary(): SummaryNode {
        if (this.state.importSourceType === "space") {
            if (this.state.importFromTaskId) {
                return Summary.summary(<div>Importing from a task ({this.sanitiseTaskDescription(this.state.tasks.find((t) => t.Id === this.state.importFromTaskId)?.Description ?? "unknown taskId")}) in another space.</div>);
            } else {
                return Summary.summary(<div>Importing from a task in another space. No task selected.</div>);
            }
        }
        if (this.state.importSourceType === "upload") {
            if (this.state.importFromFileId) {
                return Summary.summary(<div>Importing from an uploaded file. File has been uploaded.</div>);
            } else {
                return Summary.summary(<div>Importing from an uploaded file. No file uploaded.</div>);
            }
        }
        return Summary.default(`Unknown import source type ${this.state.importSourceType}`);
    }

    passwordSummary(): SummaryNode {
        return this.state.password && this.state.password.NewValue && this.state.password.NewValue !== "" ? Summary.summary(<div>Password has been provided</div>) : Summary.default("No password has been set");
    }
}

const ImportProjects: React.FC = () => {
    return <ImportProjectsInternal />;
};

export default ImportProjects;

/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/consistent-type-assertions */

import * as React from "react";
import pluginRegistry, { ActionEditProps } from "../pluginRegistry";
import { BaseComponent } from "components/BaseComponent/BaseComponent";
import { ActionSummaryProps } from "../actionSummaryProps";
import { PropertyValueResource } from "client/resources";
import ExpanderSectionHeading from "components/form/Sections/FormSectionHeading";
import { ExpandableFormSection, Summary, SummaryNode } from "components/form";
import { ActionExecutionLocation } from "../../../client/resources/actionExecutionLocation";
import { TargetRoles } from "areas/projects/components/Process/types";
import { BoundSensitive } from "components/form/Sensitive/Sensitive";

class JiraServiceDeskChangeRequestActionSummary extends BaseComponent<ActionSummaryProps, never> {
    constructor(props: ActionSummaryProps) {
        super(props);
    }

    render() {
        const properties = this.props.properties;
        const serviceId = properties["Octopus.Action.JiraIntegration.ServiceDesk.ServiceId"] as string;
        return <div>Service Id {serviceId ? "Set" : "Not set"}</div>;
    }
}

interface JiraServiceDeskChangeRequestProperties {
    "Octopus.Action.JiraIntegration.ServiceDesk.ServiceId": PropertyValueResource;
}

class JiraServiceDeskChangeRequestActionEdit extends BaseComponent<ActionEditProps<JiraServiceDeskChangeRequestProperties>, never> {
    constructor(props: ActionEditProps<JiraServiceDeskChangeRequestProperties>) {
        super(props);
    }

    render() {
        const properties = this.props.properties;
        return (
            <div>
                <ExpanderSectionHeading title="Jira Service Desk" />

                <ExpandableFormSection
                    errorKey="Octopus.Action.JiraIntegration.ServiceDesk.ServiceId"
                    isExpandedByDefault={this.props.expandedByDefault}
                    title="Service Id"
                    help="Provide the Jira Service Desk Service Id."
                    summary={this.serviceIdSummary()}
                >
                    <BoundSensitive value={properties["Octopus.Action.JiraIntegration.ServiceDesk.ServiceId"]!} onChange={(val) => this.props.setProperties({ ["Octopus.Action.JiraIntegration.ServiceDesk.ServiceId"]: val })} label="Service Id" />
                </ExpandableFormSection>
            </div>
        );
    }

    serviceIdSummary(): SummaryNode {
        const val = this.props.properties["Octopus.Action.JiraIntegration.ServiceDesk.ServiceId"];
        return val ? Summary.placeholder("The Service Id has been provided") : Summary.placeholder("The Service Id has not been provided");
    }
}

pluginRegistry.registerActionForAllScopes({
    executionLocation: ActionExecutionLocation.AlwaysOnServer,
    canRunOnWorker: false,
    actionType: "Octopus.JiraIntegration.ServiceDeskAction",
    summary: (properties, targetRolesAsCSV) => <JiraServiceDeskChangeRequestActionSummary properties={properties} targetRolesAsCSV={targetRolesAsCSV} />,
    canHaveChildren: () => false,
    canBeChild: true,
    edit: JiraServiceDeskChangeRequestActionEdit,
    targetRoleOption: () => TargetRoles.None,
});

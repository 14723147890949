import { SpecifiedBranch, BranchSpecifier, UseDefaultBranch, ShouldUseDefaultBranch } from "../components/ProjectsRoutes/BranchSpecifier";
import { repository } from "clientInstance";
import { ProjectResource, VcsBranchResource } from "client/resources";

function lastAccessedBranchStore() {
    const LocalStorageKey = "octoLastAccessedBranch";

    const buildKey = (projectIdOrSlug: string) => {
        return `${LocalStorageKey}-${projectIdOrSlug}`;
    };

    const save = async (project: ProjectResource, branch: SpecifiedBranch): Promise<VcsBranchResource> => {
        if (!project.IsVersionControlled) {
            throw new Error("Can only retrieve stored branches for VCS projects");
        }

        // Note: Retrieve the branch before we save it to ensure it exists
        const branchResource = await repository.Projects.getBranch(project, branch);

        localStorage.setItem(buildKey(project.Id), branch);

        return branchResource;
    };

    const get = async (project: ProjectResource): Promise<VcsBranchResource> => {
        if (!project.IsVersionControlled) {
            throw new Error("Can only retrieve stored branches for VCS projects");
        }

        const key = buildKey(project.Id);
        const storedBranch = localStorage.getItem(key) ?? UseDefaultBranch;

        if (!ShouldUseDefaultBranch(storedBranch)) {
            try {
                return await repository.Projects.getBranch(project, storedBranch);
            } catch (e) {
                localStorage.removeItem(key);
            }
        }

        return repository.Projects.getBranch(project, project.VersionControlSettings.DefaultBranch);
    };

    const getBranchName = (project: ProjectResource): BranchSpecifier => {
        if (!project.IsVersionControlled) {
            throw new Error("Can only retrieve stored branches for VCS projects");
        }

        const key = buildKey(project.Id);
        return localStorage.getItem(key) ?? UseDefaultBranch;
    };

    return {
        save,
        get,
        getBranchName,
    };
}

const lastAccessedBranch = lastAccessedBranchStore();

export { lastAccessedBranch };

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as React from "react";
import { NavLink } from "react-router-dom";
import { RouteComponentProps, withRouter } from "react-router";
import { exposeComponentAsClass } from "../exposeComponentAsClass";
import { resolvePathWithSpaceId, resolveStringPathWithSpaceId } from "../resolvePathWithSpaceId";
import { LocationDescriptor } from "history";
import { isUrlActive } from "../isUrlActive";
import { toPath } from "../toPath";

interface InternalNavLinkComponentProps extends React.HTMLProps<HTMLElement> {
    className?: string;
    activeClassName?: string;
    to: string;
    exact?: boolean;
}

type InternalNavLinkProps = InternalNavLinkComponentProps & RouteComponentProps<{ spaceId: string }>;

const InternalNavLink: React.SFC<InternalNavLinkProps> = (props: InternalNavLinkProps) => {
    const resolvedTo = resolvePathWithSpaceId(props.to, props.match.params.spaceId);
    /* eslint-disable react/forbid-elements */
    return (
        <NavLink
            className={props.className}
            key={props.to}
            to={resolvedTo}
            exact={props.exact}
            isActive={() => isUrlActive(props.location.pathname, props.match.params.spaceId, toPath(resolvedTo)!, props.exact!)}
            activeClassName={props.activeClassName}
        >
            {props.children}
        </NavLink>
    );
};

export default exposeComponentAsClass(withRouter(InternalNavLink));

import { ProjectResource, VcsBranchResource } from "../../client/resources";
import { Item } from "../../primitiveComponents/form/Select/Select";

const getTextValuePairForBranch = (branch: VcsBranchResource, project: ProjectResource): Item => {
    const isDefaultBranch = branch.Name === project.VersionControlSettings.DefaultBranch;
    return {
        text: `${branch.Name} ${isDefaultBranch ? "(default)" : ""}`,
        value: branch.Name,
    };
};

const buildBranchNamesList = (branches: VcsBranchResource[], project: ProjectResource): Item[] => {
    return branches.map((branch) => getTextValuePairForBranch(branch, project));
};

export { getTextValuePairForBranch, buildBranchNamesList };

/* eslint-disable @typescript-eslint/no-explicit-any */

import * as React from "react";
import cn from "classnames";
import MediaQuery from "react-responsive";
import DateFormatter from "utils/DateFormatter";
import { DataTableRow, DataTableRowColumn } from "primitiveComponents/dataDisplay/DataTable";
const styles = require("./style.less");
import { PhaseResource } from "client/resources";
import { LifecycleStatus } from "../../../../../utils/MapProgressionToStatus/MapProgressionToStatus";
import InternalLink from "../../../../../components/Navigation/InternalLink/InternalLink";
import DeploymentDetailsIcon from "./DeploymentDetailsIcon";
import { TaskResource } from "../../../../../client/resources/taskResource";
import { EnvironmentResource } from "../../../../../client/resources/environmentResource";
import { ResourcesById } from "../../../../../client/repositories/basicRepository";
import { DeploymentResource } from "../../../../../client/resources/deploymentResource";
import routeLinks from "../../../../../routeLinks";
import PhaseStatusIcon from "../../../../../components/PhaseStatusIcon/PhaseStatusIcon";
import CardExpandable from "components/form/Sections/CardExpandable";
const breakpoint = 600;

interface DeploymentRowProps {
    phase: PhaseResource;
    lifecycleStatus: LifecycleStatus;
    deployment: DeploymentResource;
    task: TaskResource<{ DeploymentId: string }>;
    title?: string;
    showChildRows: boolean;
    environmentsById: ResourcesById<EnvironmentResource>;
    actionButton?: JSX.Element | null;
    onToggleChildRows(): void;
    [others: string]: any;
}

const DeploymentRow: React.StatelessComponent<DeploymentRowProps> = (props) => {
    const childRowExpander = () => {
        return (
            <div className={cn(styles.deploymentExpander, styles.expandCollapse)}>
                <CardExpandable expanded={props.showChildRows} onExpanding={props.onToggleChildRows} />
            </div>
        );
    };

    return (
        <DataTableRow key={`${props.deployment.Id}`} className={cn(props.className, styles.deploymentsTableRow)}>
            <DataTableRowColumn>
                <PhaseStatusIcon phase={props.phase} status={props.lifecycleStatus} environmentId={props.environmentsById[props.deployment.EnvironmentId].Id} />
                <span>{props.title || props.environmentsById[props.deployment.EnvironmentId].Name}</span>
                <MediaQuery maxWidth={breakpoint - 1}>{props.deployments.length > 1 ? childRowExpander() : null}</MediaQuery>
            </DataTableRowColumn>
            <DataTableRowColumn>
                <InternalLink to={routeLinks.task(props.deployment).root}>
                    <DeploymentDetailsIcon task={props.task} deployment={props.deployment} stripTopBottomPadding={true} />
                </InternalLink>
            </DataTableRowColumn>
            <DataTableRowColumn>
                <span className={styles.deploymentDate}>{DateFormatter.dateToLongFormat(props.task.StartTime || props.task.QueueTime)}</span>
                {props.task.Duration && <div className={styles.deploymentDuration}>Duration: {props.task.Duration}</div>}
            </DataTableRowColumn>
            <MediaQuery minWidth={breakpoint}>
                <DataTableRowColumn className={styles.deploymentRowButton}>
                    {props.actionButton}
                    {props.deployments.length > 1 ? childRowExpander() : <div className={styles.deploymentExpander} />}
                </DataTableRowColumn>
            </MediaQuery>
        </DataTableRow>
    );
};

DeploymentRow.displayName = "DeploymentRow";
export default DeploymentRow;

import { Switch } from "react-router-dom";
import ReloadableRoute from "components/ReloadableRoute";
import routeLinks from "routeLinks";
import AuthenticationLayout from "areas/authentication/AuthenticationLayout";
import SignIn from "areas/authentication/SignIn";
import SignOut from "areas/authentication/SignOut";
import Register from "areas/authentication/Register";
import SecureRoute from "components/SecureRoute";
import { StandardLayout } from "components/StandardLayout";
import * as React from "react";

export function AuthenticationRoutes() {
    return (
        <Switch>
            <ReloadableRoute
                path={routeLinks.currentUser.signIn}
                render={(props) => (
                    <AuthenticationLayout>
                        <SignIn {...props} />
                    </AuthenticationLayout>
                )}
            />
            <ReloadableRoute
                path={routeLinks.currentUser.signOut}
                render={(props) => (
                    <AuthenticationLayout>
                        <SignOut {...props} />
                    </AuthenticationLayout>
                )}
            />
            <ReloadableRoute
                path={routeLinks.currentUser.register(":inviteCode")}
                render={(props) => (
                    <AuthenticationLayout>
                        <Register {...props} />
                    </AuthenticationLayout>
                )}
            />
            <SecureRoute path={routeLinks.root} component={StandardLayout} />
        </Switch>
    );
}

export type PropertyValueResource = string | SensitiveValue | null;

export interface SensitiveValue {
    HasValue: boolean;
    NewValue?: string;
    Hint?: string;
}

export function isSensitiveValue(value: PropertyValueResource) {
    if (typeof value === "string" || value === null) {
        return false;
    }

    return Object.prototype.hasOwnProperty.call(value, "HasValue");
}

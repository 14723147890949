/* eslint-disable @typescript-eslint/no-explicit-any */

import { SpaceScopedResource, NamedResource, TenantedDeploymentMode, ActionTemplateParameterResource, SensitiveValue, NewSpaceScopedResource, ResourceWithLinks } from "client/resources";
import { DeploymentActionPackageResource } from "./deploymentActionPackageResource";
import { MetadataTypeCollection } from "./dynamicFormResources";
import { ExtensionSettingsValues } from "./extensionSettingsValues";
import { GitRef } from "./versionControlledResource";

interface BaseProjectResourceLinks {
    Self: string;
    Releases: string;
    Channels: string;
    Triggers: string;
    ScheduledTriggers: string;
    OrderChannels: string;
    Variables: string;
    Progression: string;
    RunbookTaskRunDashboardItemsTemplate: string;
    DeploymentProcess: string;
    Web: string;
    Logo: string;
    Metadata: string;
    Runbooks: string;
    RunbookSnapshots: string;
    Summary: string;
}

type VcsProjectResourceLinks = BaseProjectResourceLinks & {
    Branches: string;
};

type ProjectResourceLinks = BaseProjectResourceLinks | VcsProjectResourceLinks;

export function HasVcsProjectResourceLinks(links: ProjectResourceLinks): links is VcsProjectResourceLinks {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return (links as VcsProjectResourceLinks).Branches !== undefined;
}

export interface VersionControlSettingsResource {
    Url: string;
    Username: string;
    Password: SensitiveValue;
    DefaultBranch: string;
    BasePath: string;
}

export interface ProjectResource extends NamedResource<ProjectResourceLinks>, SpaceScopedResource {
    VariableSetId: string;
    DeploymentProcessId: string;
    DiscreteChannelRelease: boolean;
    IncludedLibraryVariableSetIds: string[];
    DefaultToSkipIfAlreadyInstalled: boolean;
    TenantedDeploymentMode: TenantedDeploymentMode;
    VersioningStrategy: VersioningStrategy;
    ReleaseCreationStrategy: ReleaseCreationStrategy;
    Templates: ActionTemplateParameterResource[];
    AutoDeployReleaseOverrides: any[];
    LifecycleId: string;
    AutoCreateRelease: boolean;
    ProjectConnectivityPolicy: ProjectConnectivityPolicy;
    DefaultGuidedFailureMode: GuidedFailureMode;
    ClonedFromProjectId: string;
    ExtensionSettings: ExtensionSettingsValues[];
    ReleaseNotesTemplate: string;
    DeploymentChangesTemplate: string;
    IsVersionControlled: boolean;
    VersionControlSettings: VersionControlSettingsResource;
    Slug: string;
    ProjectGroupId: string;
    Description: string;
    IsDisabled: boolean;
}

export type ProjectOrSummaryResource = ProjectResource | ProjectSummaryResource;

export interface ProjectSummaryResource extends NamedResource<ProjectResourceLinks>, SpaceScopedResource {
    Slug: string;
    ProjectGroupId: string;
    Description: string;
    IsDisabled: boolean;
}

export interface NewProjectResource extends NewSpaceScopedResource {
    Name: string;
    Description: string;
    ProjectGroupId: string;
    LifecycleId: string;
}

export interface ProjectSettingsMetadata {
    ExtensionId: string;
    Metadata: MetadataTypeCollection;
}

export interface VersioningStrategy {
    Template: string;
    DonorPackage?: DeploymentActionPackageResource;
    DonorPackageStepId?: string;
}

export interface ReleaseCreationStrategy {
    ReleaseCreationPackage: DeploymentActionPackageResource;
    ChannelId?: string;
    ReleaseCreationPackageStepId?: string;
}

export interface ProjectConnectivityPolicy {
    SkipMachineBehavior: string;
    TargetRoles: string[];
    AllowDeploymentsToNoTargets: boolean;
    ExcludeUnhealthyTargets: boolean;
}

export interface VersionControlCompatibilityResponse {
    Errors: string[];
    Warnings: string[];
    Notices: string[];
}

export enum GuidedFailureMode {
    EnvironmentDefault = "EnvironmentDefault",
    Off = "Off",
    On = "On",
}

export interface RecentlyViewedProjectIds {
    [key: string]: string[];
}

export interface ProjectSummary {
    HasRunbooks: boolean;
    HasDeploymentProcess: boolean;
}

type VcsBranchResourceLinks = {
    DeploymentProcess: string;
    Runbook: string;
    ReleaseTemplate: string;
};

export interface VcsBranchResource extends ResourceWithLinks<VcsBranchResourceLinks> {
    Name: GitRef;
}

export default ProjectResource;

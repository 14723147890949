import { useHistory, useParams } from "react-router";
import { resolveStringPathWithSpaceId } from "components/Navigation/resolvePathWithSpaceId";

export function useSpaceAwareHistory() {
    const history = useHistory();
    const params = useParams<{ spaceId: string | undefined }>();
    return new SpaceAwareHistory(history, params.spaceId);
}

export class SpaceAwareHistory {
    constructor(private readonly history: ReturnType<typeof useHistory>, private readonly spaceId: string | undefined) {}
    push = (path: string) => {
        this.history.push(resolveStringPathWithSpaceId(path, this.spaceId));
    };
}

import * as React from "react";
import { EventAgentResource } from "client/resources";
import { MultiSelect } from "components/MultiSelect";
import { FormFieldProps } from "components/form";
import { EventAgentChip, MissingChip } from "components/Chips";
import { ChipIcon } from "components/Chips";
import Lookup from "../Lookup";
import { SelectItem } from "../VirtualListWithKeyboard/SelectItem";

interface EventAgentMultiSelectProps extends FormFieldProps<string[]> {
    items: EventAgentResource[];
    label?: string | JSX.Element;
    error?: string;
}

const EventAgentTypedMultiSelect = MultiSelect<EventAgentResource>();

const EventAgentMultiSelect: React.FC<EventAgentMultiSelectProps> = (props) => {
    const chipRenderer = (r: EventAgentResource | SelectItem, onRequestDelete: (event: object) => void) => {
        return (
            <Lookup
                lookupCollection={props.items}
                lookupId={r.Id}
                getIdFromElement={(element) => element.Id}
                render={(item) => <EventAgentChip deleteButtonAccessibleName={`Delete ${item.Name}`} onRequestDelete={onRequestDelete} eventAgent={item} />}
                renderFallback={<MissingChip lookupId={r.Id} type={ChipIcon.EventAgent} deleteButtonAccessibleName={"Delete Missing Resource"} onRequestDelete={onRequestDelete} />}
            />
        );
    };

    return <EventAgentTypedMultiSelect fieldName="event agents" renderChip={chipRenderer} {...props} />;
};

export default EventAgentMultiSelect;

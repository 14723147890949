import * as React from "react";

import { DataBaseComponent, DataBaseComponentState } from "components/DataBaseComponent/DataBaseComponent";
import { EnvironmentSummaryResource, EnvironmentsSummaryResource } from "client/resources";
import { OctopusIcon, OctopusIconType } from "primitiveComponents/dataDisplay/Icon";

import BusyIndicator from "components/BusyIndicator";
import FilterSearchBox from "components/FilterSearchBox/FilterSearchBox";
import InternalLink from "components/Navigation/InternalLink";
import { Section } from "components/Section/Section";
import routeLinks from "../../../../routeLinks";
import { withTheme } from "components/Theme";

const styles = require("./style.less");

interface EnvironmentsCardProps {
    environmentSummary: EnvironmentsSummaryResource | undefined;
}

interface EnvironmentsCardState extends DataBaseComponentState {
    filterText: string;
}

export default class EnvironmentsCard extends DataBaseComponent<EnvironmentsCardProps, EnvironmentsCardState> {
    constructor(props: EnvironmentsCardProps) {
        super(props);
        this.state = {
            filterText: "",
        };
    }

    render() {
        if (!this.props.environmentSummary) {
            return withTheme((theme) => (
                <div className={styles.card}>
                    <div className={styles.cardTitleContainer}>
                        <div className={styles.cardTitle}>
                            <InternalLink to={routeLinks.infrastructure.environments.root} color={theme.whiteConstant}>
                                Environments
                            </InternalLink>
                        </div>
                    </div>
                    <Section>
                        <BusyIndicator inline={true} show={true} />
                    </Section>
                </div>
            ));
        }
        let environmentSummaries = this.props.environmentSummary && this.props.environmentSummary.EnvironmentSummaries;
        environmentSummaries = environmentSummaries.filter((item) => this.onFilter(this.state.filterText, item));
        const environmentRows =
            environmentSummaries &&
            environmentSummaries.map((environmentSummary, index) => {
                const totalMachines = environmentSummary.TotalMachines || 0;
                return withTheme((theme) => (
                    <div className={styles.environmentRowsContainer} key={environmentSummary.Environment.Id}>
                        <div className={styles.environmentIcon}>
                            <OctopusIcon iconType={OctopusIconType.Environment} color={theme.iconDark} />
                        </div>
                        <div className={styles.environmentName}>
                            <InternalLink to={routeLinks.infrastructure.machines.filtered({ environmentIds: environmentSummary.Environment.Id })}>{environmentSummary.Environment.Name}</InternalLink>
                        </div>
                        <div className={styles.environmentMachinesCount}>{totalMachines.toLocaleString()}</div>
                    </div>
                ));
            });

        const environementSummary: EnvironmentsSummaryResource | undefined = this.props.environmentSummary;

        return withTheme((theme) => (
            <div className={styles.card}>
                <div className={styles.cardTitleContainer}>
                    <div className={styles.cardTitle}>
                        <InternalLink to={routeLinks.infrastructure.environments.root} color={theme.whiteConstant}>
                            Environments <span className={styles.cardCount}>({environmentSummaries && environmentSummaries.length.toLocaleString()})</span>
                        </InternalLink>
                    </div>
                </div>
                <div className={styles.cardContentContainer}>
                    {(environementSummary.EnvironmentSummaries.length > 1 || this.state.filterText) && (
                        <div>
                            <FilterSearchBox
                                placeholder="Filter..."
                                onChange={(value) => this.setState({ filterText: value.toLowerCase() })}
                                inputClassName={styles.dashboardFilterInputClassName}
                                iconColor={theme.secondaryText}
                                iconStyle={{ width: "20px", height: "20px", top: "14px" }}
                            />
                        </div>
                    )}
                    {environmentRows}
                </div>
            </div>
        ));
    }

    private onFilter(filter: string, resource: EnvironmentSummaryResource) {
        return !filter || filter.length === 0 || !resource || resource.Environment.Name.toLowerCase().includes(filter.toLowerCase());
    }
}

import ExternalLink from "../../Navigation/ExternalLink";
import * as React from "react";
import pluginRegistry, { ActionEditProps } from "../../Actions/pluginRegistry";
import { BaseComponent } from "../../BaseComponent/BaseComponent";
import { ExpandableFormSection, Summary } from "../../form";
import { VariableLookupText } from "../../form/VariableLookupText";
import Note from "../../../primitiveComponents/form/Note/Note";
import ActionProperties from "client/resources/actionProperties";
import { ValueInPropertiesOrErrorsHasChanged } from "utils/ShouldUpdate/ValueInPropertiesHasChanged";

const StringProperties = {
    "Octopus.Action.SubstituteInFiles.TargetFiles": "",
    "Octopus.Action.SubstituteInFiles.OutputEncoding": "",
};

type SubstituteInFilesProperties = { [P in keyof typeof StringProperties]: string };

class SubstituteInFilesEdit extends BaseComponent<ActionEditProps<SubstituteInFilesProperties>, never> {
    shouldComponentUpdate(nextProps: ActionEditProps<SubstituteInFilesProperties>) {
        return ValueInPropertiesOrErrorsHasChanged(StringProperties, nextProps, this.props);
    }

    summary() {
        const json = this.props.properties["Octopus.Action.SubstituteInFiles.TargetFiles"] || "";
        if (json.length > 0) {
            return Summary.summary(<span>Variable substitution will be performed on configured files</span>);
        } else {
            return Summary.placeholder("No files configured");
        }
    }

    render() {
        const properties = this.props.properties;

        return (
            <ExpandableFormSection
                errorKey="Octopus.Action.SubstituteInFiles.TargetFiles|Octopus.Action.SubstituteInFiles.OutputEncoding"
                isExpandedByDefault={this.props.expandedByDefault}
                title="Substitute Variables in Templates"
                summary={this.summary()}
                help="Configure files to perform variable substitution on."
            >
                <VariableLookupText
                    localNames={this.props.localNames}
                    value={properties["Octopus.Action.SubstituteInFiles.TargetFiles"]}
                    onChange={(x) => this.props.setProperties({ ["Octopus.Action.SubstituteInFiles.TargetFiles"]: x })}
                    error={this.props.getFieldError("Octopus.Action.SubstituteInFiles.TargetFiles")}
                    multiline={true}
                    label="Target files"
                />
                <Note>
                    A newline-separated list of file names to transform, relative to the package contents. Extended wildcard syntax is supported. E.g., <em>Notes.txt</em>, <em>Config\*.json</em>, <em>**\specific-folder\*.config.</em>
                    <br />
                    This field supports extended template syntax. Conditional <code>if</code> and <code>unless</code>:<pre>{`#{if MyVar}...#{/if}`}</pre>
                    Iteration over variable sets or comma-separated values with <code>each</code>:<pre>{`#{each mv in MyVar}...#{mv}...#{/each}`}</pre>
                </Note>

                <VariableLookupText
                    localNames={this.props.localNames}
                    value={properties["Octopus.Action.SubstituteInFiles.OutputEncoding"]}
                    onChange={(x) => this.props.setProperties({ ["Octopus.Action.SubstituteInFiles.OutputEncoding"]: x })}
                    error={this.props.getFieldError("Octopus.Action.SubstituteInFiles.OutputEncoding")}
                    multiline={false}
                    placeholder="Detect from template"
                    label="Output file encoding"
                />
                <Note>
                    The name of an encoding to use when writing the transformed file. E.g., <em>utf-8</em>.<br />
                    For a list of supported encoding names, see the Remarks section of the <ExternalLink href="EncodingNames">System.Text.Encoding</ExternalLink> class on MSDN.
                </Note>
            </ExpandableFormSection>
        );
    }
}

pluginRegistry.registerFeatureForAllScopes({
    featureName: "Octopus.Features.SubstituteInFiles",
    title: "Substitute Variables in Templates",
    description: "Transforms files using the Octopus <code>#{Variable}</code> substitution syntax",
    edit: SubstituteInFilesEdit,
    priority: 22,
    disable: (properties: ActionProperties) => {
        delete properties["Octopus.Action.SubstituteInFiles.TargetFiles"];
        delete properties["Octopus.Action.SubstituteInFiles.OutputEncoding"];
    },
});
